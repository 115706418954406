<template>
  <footer id='footer' class='footer'>
    <div class='container'>
      <div class='row'>
        <FooterNav />
      </div>
      <div class='row'>
        <SocialIcons />
      </div>
    </div>
  </footer>
</template>

<script>
  import FooterNav from './FooterNav'
  import SocialIcons from './SocialIcons'
  export default {
    name: 'Footer',
    components: {
        FooterNav,
        SocialIcons
    },
    methods: {
      
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  background-color: $color-6;
  line-height: 1.37;
  padding: 2rem 0;
}



</style>
