<template>
  <div class="row">
    <div class='col-8 col-xl-12'>
      <h5 class="company-name" v-if="!mobileView">{{ companyName }}</h5>
      <p v-else class="company-name">{{ companyName }}</p>
      <div class='col d-flex' :class="mobileView ? '': 'flex-column'">
        <p>Created by <span v-if="userCreated && userCreated.first_name">{{ userCreated.first_name }} </span> <span v-if="userCreated && userCreated.last_name">{{ userCreated.last_name }}&nbsp;</span></p> 
        <p class="modified">Last mod: {{ lastModified }}</p>
      </div>
    </div>
    <div class='col'>
      <ButtonAutodesk v-if="stage === 'report-overview'" class="btn btn--autodesk-outline" label="Delete report" @clicked="deleteReport"/>
    </div>
  </div>
</template>

<script>
  import { format } from 'date-fns'
  import ButtonAutodesk from './buttons/ButtonAutodesk'
  export default {
    name: 'ReportDetail',
    props: {
        stage: String
    },
    components: {
      ButtonAutodesk
    },
    methods: {
      deleteReport () {
        this.$emit('delete-report')
      }
    },
    computed: {
      mobileView: function () {
        return this.$store.getters.mobileView
      },
      companyName: function () {
        return this.$store.getters.companyName
      },
      userCreated: function () {
        return this.$store.getters.userCreated
      },
      lastModified: function () {
        let dateTime
        if (this.$store.getters.lastModified && this.$store.getters.lastModified.length) {
          dateTime  = new Date(this.$store.getters.lastModified)
          dateTime = format(dateTime, 'do MMM yyyy, hh:mm a')
        }
        return dateTime
      },
      currentUser: function () {
        return this.$store.getters.user
      },
    }
  }
</script>

<style scoped lang="scss">
  p {
    font-size: (12/$base-font)*1rem;
    &.modified {
      font-weight: 400;
      color: $color-22;
    }
    &.company-name {
      margin-bottom: 0;
      padding: 1.5rem 0 0 0;
      font-weight: 700;
    }
  } 
  
  .col {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h5 {
      margin-right: auto;
    }
    button {
      margin-left: auto;
    }
    @media screen and (min-width: 1200px) { 
      p {
        padding: 0;
        margin-right: auto;
        margin-bottom: 0;

        &.modified {
          padding:  0  0 1rem 0;
          font-weight: 400;
          color: $color-22;
          display: block;
        }
      } 
    
      button {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }

</style>