import { ExportToCsv } from 'export-to-csv'

export function CSVExport (formattedData, companyName, stage) {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: `${companyName} - ${stage}`,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  }
  try {
    const csvExporter = new ExportToCsv(options)
    csvExporter.generateCsv(formattedData)
  } catch (err) {
    console.error(err)
  }
}
