<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
    <!-- <script type="application/javascript" :src="this.tealiumScript"></script> -->
    <notifications 
      :style="notificationPosition"
      group="custom-notification" 
      class="autodesk-notification" 
      :duration="2000" 
      :speed="500"
    >
      <template #body="props">
        <div class="vue-notification" @click="props.close">
          <img class="notify-icon" src='./assets/images/notification.svg' alt=''>
          <button class="close" @click="props.close">
            <img src='./assets/images/cross.svg' alt=''>
          </button>
          <div class='notification-content'>
            <p class="title">
              {{ props.item.title }}
            </p>
            <div v-html="props.item.text"/>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
  // window.dataLayer = window.dataLayer || [];
import { mapActions } from 'vuex'

export default {
  
  name: 'App',
  data() {
    return {
      tealiumScript: '',
    }
  },
  methods: {
    ...mapActions(['setMobileView']),
    handleSize () {
      const isMobile = window.innerWidth <= 1200
      this.setMobileView(isMobile)
    }
  },
  mounted () {
/*     const isProduction = false
    let tealiumScript = document.createElement('script')
      tealiumScript.setAttribute('type', 'text/javascript')
    if (isProduction) {
      tealiumScript.setAttribute('src', '//tags.tiqcdn.com/utag/autodesk/micro-opt/prod/utag.js')
    } else {
      tealiumScript.setAttribute('src', '//tags.tiqcdn.com/utag/autodesk/micro-opt-qa/qa/utag.js')
    }
    document.head.appendChild(tealiumScript) */
    this.tealiumScript = '//tags.tiqcdn.com/utag/autodesk/micro-opt-qa/qa/utag.js'
  },
  created () {
    this.handleSize()
    window.addEventListener('resize', this.handleSize)
  },
  computed: {
    notificationPosition () {
      let left = this.$store.getters.notificationPosition
      let width = this.$store.getters.notificationWidth
      return `left: ${left}px; top: 130px; width: ${width}px;`
    }
  }
}

</script>

<style lang="scss">

main {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}
@font-face {
  font-family: 'ArtifaktElement-Regular';
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Regular.eot');
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/element/WOFF2/Artifakt Element Regular.woff2') format('woff2'),
  url('./assets/fonts/element/WOFF/Artifakt Element Regular.woff') format('woff'),
  url('./assets/fonts/element/TTF/Artifakt Element Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktLegend-Regular';
  src: url('./assets/fonts/legend/EOT/ArtifaktLegend-Regular.eot');
  src: url('./assets/fonts/legend/EOT/ArtifaktLegend-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/legend/WOFF2/Artifakt Legend Regular.woff2') format('woff2'),
  url('./assets/fonts/legend/WOFF/Artifakt Legend Regular.woff') format('woff'),
  url('./assets/fonts/legend/TTF/Artifakt Legend Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktElement-Bold';
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Bold.eot');
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/element/WOFF2/Artifakt Element Bold.woff2') format('woff2'),
  url('./assets/fonts/element/WOFF/Artifakt Element Bold.woff') format('woff'),
  url('./assets/fonts/element/TTF/Artifakt Element Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktLegend-ExtraBold';
  src: url('./assets/fonts/legend/WOFF2/Artifakt Legend Extra Bold.woff2') format('woff2'),
  url('./assets/fonts/legend/WOFF/Artifakt Legend Extra Bold.woff') format('woff'),
  url('./assets/fonts/legend/TTF/Artifakt Legend Extra Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktLegend-Bold';
  src: url('./assets/fonts/legend/EOT/ArtifaktLegend-Bold.eot');
  src: url('./assets/fonts/legend/EOT/ArtifaktLegend-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/legend/WOFF2/Artifakt Legend Bold.woff2') format('woff2'),
  url('./assets/fonts/legend/WOFF/Artifakt Legend Bold.woff') format('woff'),
  url('./assets/fonts/legend/TTF/Artifakt Legend Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktElement-Black';
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Black.eot');
  src: url('./assets/fonts/element/EOT/ArtifaktElement-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/element/WOFF2/Artifakt Element Black.woff2') format('woff2'),
  url('./assets/fonts/element/WOFF/Artifakt Element Black.woff') format('woff'),
  url('./assets/fonts/element/TTF/Artifakt Element Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArtifaktLegend-Black';
  src: url('./assets/fonts/legend/EOT/Artifakt Legend Black.eot');
  src: url('./assets/fonts/legend/EOT/Artifakt Legend Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/legend/WOFF2/Artifakt Legend Black.woff2') format('woff2'),
  url('./assets/fonts/legend/WOFF/Artifakt Legend Black.woff') format('woff'),
  url('./assets/fonts/legend/TTF/Artifakt Legend Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

</style>
