<template>
  <div class="page-wrapper">
    <!-- <component :is="'script'" :src="tealium" type="application/javascript"></component> -->
    <Header />
    <main class="container">
      <div class="row">
        <div class="col topBlock">
          <h1>Sustainability directory</h1>
          <h5 class="standfirst">
            Log all the information about your client's sustainability
            initiatives, find existing reports or start fresh by creating a new
            one.
          </h5>
          <SearchForm :hasButton="true" />
          <h3>Create a new report</h3>
          <ButtonAutodesk
            class="btn btn--autodesk-dark"
            label="Start now"
            @clicked="createReport"
          />
        </div>
      </div>
      <div v-if="myLatestReports.length" class="cards">
        <div class="row">
          <div class="col" id="my-latest-reports">
            <div class="header-wrapper">
              <h5>My latest reports</h5>
              <ButtonAutodesk
                class="btn btn--autodesk-step-next ms-auto"
                label="See my reports"
                @clicked="myReports"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <reportCard
            v-for="report in myLatestReports"
            :key="report.id"
            :report="report"
            tag="h3"
            col="4"
          />
        </div>
      </div>
      <div v-if="latestReports.length" class="cards">
        <div class="row">
          <div class="col" id="latest-reports">
            <div class="header-wrapper">
              <h5>All latest reports</h5>
              <ButtonAutodesk
                class="btn btn--autodesk-step-next ms-auto"
                label="See all reports"
                @clicked="allReports"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <reportCard
            v-for="report in latestReports"
            :key="report.id"
            :report="report"
            tag="h4"
            col="3"
          />
        </div>
        <div class="slack">
          <h5>
          <a href="https://autodesk.slack.com/archives/C0470S1UV51">Access Help and Support via Slack</a>
          </h5>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer";
import Header from "../components/Header";
import SearchForm from "../components/forms/SearchForm";
import reportCard from "../components/reportCard";
import ButtonAutodesk from "../components/buttons/ButtonAutodesk";
import { axiosInstance } from "../services/api";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Footer,
    Header,
    SearchForm,
    reportCard,
    ButtonAutodesk,
  },
  data() {
    return {
      myLatestReports: [],
      latestReports: [],
      fields:
        "company_name,id,date_created,date_updated,industry_sector.*,region.label,short_description,industry_sector,user_created.first_name,user_created.last_name,user_updated.first_name,user_updated.last_name",
    };
  },
  methods: {
    async getMyLatestReports() {
      try {
        let fields = this.fields;

        axiosInstance
          .get(`items/reports?limit=4&fields=${fields}&sort[]=-date_created`)
          .then((response) => {
            this.latestReports = response.data.data;
            axiosInstance
              .get(
                `items/reports?limit=3&fields=${fields}&filter[user_created][_eq]=${this.$store.getters.user.id}&sort[]=-date_created`
              )
              .then((response) => {
                this.myLatestReports = response.data.data;
              });
          });
      } catch (error) {
        console.log(error.response);
      }
    },
    createReport() {
      this.$router.push(`/reports/create-report`);
    },
    myReports() {
      this.$router.push("/my-reports");
    },
    allReports() {
      this.$router.push("/all-reports");
    },
  },
  created() {
    this.getMyLatestReports();
  },
  computed: {
    ...mapGetters(["tealium"]),
  },
};
</script>

<style lang="scss">
.topBlock {
  text-align: center;
  margin-bottom: (111 / $base-font) * 1rem;
  h5 {
    max-width: 696px;
    margin: auto;
  }
  h3 {
    margin-top: 104px;
  }
}
.cards {
  margin-bottom: 100px;
}

h5.standfirst {
  font-family: ArtifaktElement-Regular;
  font-weight: normal;
}
.header-wrapper {
  display: flex;
  align-items: flex-start;
}
.btn--autodesk-step-next {
  padding-top: 5px;
}
#my-latest-reports,
#latest-reports {
  margin-bottom: 0;
  h5 {
    margin-bottom: (8 / $base-font) * 1rem;
  }
}
.slack {
  text-align: center;
  padding: 50px 0 0;
  a {
    color: #000;
    text-decoration: none;
    &:hover{
      color:rgba(0, 0, 0, 0.6);
    }
  }
}
</style>
