<template>
  <div>
    <h2>Sustainability goals</h2>
    <h6 class="small">Provide information about any sustainability goals your company may have.</h6>
    <form @submit.prevent="save" class='sustainability-goal autodesk-form'>
      <div class="row">
        <div class='col'>
          <div class="form-group">
            <Select 
              label="Sustainability goal" 
              name="sustainability-goal" 
              :stripFirstWord="true" 
              :firstIsPlaceholder="true" 
              v-model="sustainabilityGoalOption" 
              :options="sustainabilityGoalOptions"
              :isClearable="false"
              :isGoals="true"
              toolTip="UNSDG Goal that matches closest to the customer’s stated goal on their website or in reporting."
              />
          </div>
        </div>
      </div>
      <div class="form-group">
        <Text 
          name="url" 
          label="URL" 
          v-model="url"
          :maxCharachterCount="99"
          type="url"
          placeHolder="https://www.linkedin.com/in/example"
          errorMessage="Please enter a valid URL"
        />
      </div>
      <FileUpload @deleteFile="deleteFile" @file-uploaded="fileUploaded" @file-error="fileError" :images="images"/>
      <div class="form-group">
        <TextArea 
            label="Additional Information" 
            v-model="additionalInformation"
            :height="22"
            toolTip="Goal metrics, target timeframe to meet the goal, progress to-date, etc."
          />
      </div>
      
      <div class='d-flex form-buttons'>
        <ButtonAutodesk class="btn btn--autodesk-outline" label="Save changes" @clicked="clickSave"/>
        <ButtonAutodesk class="btn btn--autodesk-text" label="Delete entry" @clicked="deleteItem"/>
        <ButtonAutodesk class="btn btn--autodesk-dark ms-auto" label="Next" @clicked="nextSection"/>
        <Confirmation 
          title="Are you sure you want to delete this goal?" 
          positionClass="above" 
          @confirm="confirmDeleteItem()" 
          @cancel="cancelDelete" 
          v-if="showConfirmation === true" />
      </div>
    </form>
  </div>
</template>

<script>
  import validator from 'validator'
  import { axiosInstance, deleteItemImages, removeListItem, createNewAuditLogEntry } from '../../services/api'
  import { orderGoals } from '../../services/helpers'
  import FileUpload from '../forms/FileUpload'
  import Confirmation from '../Confirmation.vue'
  import TextArea from '../forms/TextArea'
  import Text from '../forms/Text'
  import Select from '../forms/Select'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'

  export default {
    name: 'ReportGoal',
    components: {
      FileUpload,
      Confirmation,
      TextArea,
      Text,
      Select,
      ButtonAutodesk
    },
    props: {
      stage: String,
    },
    data() {
      return {
        showConfirmation: '',
        isInitialItem: false
      };
    },
    methods: {
      async deleteFile (fileID) {
        // get the correct item in image array
        let imageArray = this.images.filter(function(img) {
          if (img.directus_files_id && img.directus_files_id !== null && img.directus_files_id.id !== fileID) {
            return true
          }
        })
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        //remove the file from the server
        try {
          axiosInstance.delete(`files/${fileID}`)
          createNewAuditLogEntry(this.$store.getters.reportID, 'Sustainability goal image deleted', false)
        } catch (error) {
          console.log('file deleting error')
        }
      },

      async fileUploaded (fileObject) {
        let imageArray = this.images
        imageArray = [{ directus_files_id: fileObject } , ...imageArray]
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        createNewAuditLogEntry(this.$store.getters.reportID, 'Sustainability goal image added', false)
      },
      fileError () {
        console.log('file error')
      },
      validateFields () {
        // assume all fields pass validation
        let validate = true
        const validateCompanyWebsite = this.$store.getters.companyWebsite && this.$store.getters.companyWebsite.length ? validator.isURL(this.$store.getters.companyWebsite) : true
        const validateCompanyLinkedin = this.$store.getters.companyLinkedinProfile && this.$store.getters.companyLinkedinProfile.length ? validator.isURL(this.$store.getters.companyLinkedinProfile) : true
        if (!validateCompanyWebsite || !validateCompanyLinkedin) {
          validate = false
        }

        let itemsInvalid = []
        this.reportLinkedItems.forEach(item => {
          const validateUrl = item.url && item.url.length ? validator.isURL(item.url) : true
          if (validateUrl === false) {
            validate = false
            itemsInvalid.push(item)
          }
        })

        if (validate === false) {
          this.$notify({ 
            title: "Invalid fields - please check",
            group: 'custom-notification'
           })
        }
        // set active item 
        return validate
      },

      clickSave () {
        const validated = this.validateFields()
        if (validated) {
          this.saveSection()
        }
      },

      async saveSection () {

        const reportID = this.$store.getters.reportID
        const serialNumber = this.$store.getters.customerSerialNumber
        const companySize = this.$store.getters.reportCompanySize
        const website = this.$store.getters.companyWebsite
        const location = this.$store.getters.companyLocation
        const linkedin = this.$store.getters.companyLinkedinProfile
       
        let goals = orderGoals(this.reportLinkedItems)
        this.reportLinkedItems = goals

        const sectionUpdate = {
          company_size: companySize,
          customer_serial_number: serialNumber,
          company_location: location,
          company_linkedin_profile: linkedin,
          company_website: website,
          sustainability_goals: goals
        }
        try {
          // save the section ensuring goals are included 
          const response = await axiosInstance.patch(`items/reports/${reportID}`, sectionUpdate)
          console.log(response)
          this.$notify({  
            title: "Section saved",
            group: 'custom-notification'
          })
          try {
            // log initial item creation
            if (this.isInitialItem && this.sustainabilityGoalOption.slug !== 'select-a-sustainability-goal') {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Sustainability goal created', false)
              this.isInitialItem = false
            } else {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Sustainability goal section updated', this.stage, this.$store.getters.sustainabilityGoalsHasUpdates)
            }
            createNewAuditLogEntry(this.$store.getters.reportID, 'Company section updated', this.stage, this.$store.getters.companyHasUpdates)
          } catch (error) {
            console.log('audit', error)
          }
          
          this.$store.commit('setSustainabilityGoalsHasUpdates', false)
          this.$store.commit('setCompanyHasUpdates', false)
          return true

        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
           })
        }
      },
      async saveItem (currentItem) {
        try { 
          const goalResponse = await axiosInstance.patch(`items/sustainability_goals/${currentItem.id}`, {
            sustainability_goal: currentItem.sustainabilityGoal,
            url: currentItem.url,
            images: currentItem.images,
            additional_information: currentItem.additionalInformation
          })
          console.log('goalResponse', goalResponse)
        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
          })
        }
      },
      nextSection () {
        const save = this.validateFields()
        if (save) {
          this.saveSection()
          this.$router.push('contacts')
        }
      },

      deleteItem () {
        this.showConfirmation = true
      },
      confirmDeleteItem () {
        const item = this.activeItemObject
        // delete all linked images from item
        deleteItemImages(item)

        // delete the item only if more than one - otherwise clear it
        if (this.reportLinkedItems.length > 1) { 
          const updatedItemList = removeListItem(item, this.reportLinkedItems, 'sustainability_goals')
          this.reportLinkedItems = updatedItemList
        } else {
          // set to default goal option
          this.activeItemObject.sustainability_goal = this.sustainabilityGoalOption[0]
          this.activeItemObject.url = ''
          this.activeItemObject.images = []
          this.activeItemObject.additional_information = ''
        }

        //save the section
        this.saveSection().then(() => {
          this.showConfirmation = false
        })
        createNewAuditLogEntry(this.$store.getters.reportID, 'Sustainability goal deleted')
        
      },
      cancelDelete () {
        this.showConfirmation = false
      }
    },
    computed: {
      mobileView: function () {
        return this.$store.getters.mobileView
      },
      // related item index relevant to selection we are on
      activeItemIndex: function () {
        return this.$store.getters.activeGoal
      },
      // related item relevant to selection we are on
      activeItemObject: function () {
        return this.reportLinkedItems[this.$store.getters.activeGoal]
      },
      // list of related items relevant to selection we are on
      reportLinkedItems: {
        get () {
          return this.$store.getters.reportSustainabilityGoals
        },
        set (value) {
          this.$store.commit('setReportSustainabilityGoals', value)
        }
      },

      sustainabilityGoalOptions: function () {
        return this.$store.getters.sustainabilityGoalOptions
      },
      
      // the option
      sustainabilityGoalOption: {
        get () {
          try{
            // initial option default
            let goal = this.$store.getters.sustainabilityGoalOptions[0]
            // if report goal selected already has a title
            if (this.activeItemObject && this.activeItemObject.sustainability_goal && Object.keys(this.activeItemObject.sustainability_goal).length !== 0) {
              goal = this.activeItemObject.sustainability_goal
            }
            // Add number to the string label
            if (goal.number && goal.label) {
              goal.goalLabel = `${goal.number}. ${goal.label}`
            } else if (goal.label) {
              goal.goalLabel = goal.label
            }
            return goal
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportGoals = this.reportLinkedItems
            reportGoals[this.activeItemIndex].sustainability_goal = value
            this.$store.commit('setReportSustainabilityGoals', reportGoals)
            this.$store.commit('setSustainabilityGoalsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      additionalInformation: {
        get () {
          try {
            return this.activeItemObject.additional_information
          } catch (error) {
            console.log('try error', error)
          return ''
          }
        },
        set (value) {
          try {
            let reportGoals = this.reportLinkedItems
            reportGoals[this.activeItemIndex].additional_information = value
            this.$store.commit('setReportSustainabilityGoals', reportGoals)
            this.$store.commit('setSustainabilityGoalsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      url: {
        get () {
          try {
            return this.activeItemObject.url
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportGoals = this.reportLinkedItems
            reportGoals[this.activeItemIndex].url = value
            this.$store.commit('setReportSustainabilityGoals', reportGoals)
            this.$store.commit('setSustainabilityGoalsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      images: {
        get () {
          try {
            return this.activeItemObject.images
          } catch (error) {
            console.log('try images error', error)
            return ''
          }
        },
        set (value) {
          try {
            const imagesChecked = value.filter(img => {
              if (img.directus_files_id && img.directus_files_id !== null) {
                return true
              }
            })
            let reportGoals = this.$store.getters.reportSustainabilityGoals
            reportGoals[this.activeItemIndex].images = imagesChecked
            this.$store.commit('setReportSustainabilityGoals', reportGoals)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      }
    },
    mounted () {
      // as first items are initialized on report creation - extra check is needed when a item is selected/title changes on only child item
      if (this.reportLinkedItems.length <= 1 && 
        (this.sustainabilityGoalOption === null || this.sustainabilityGoalOption.slug === 'select-a-sustainability-goal')) 
      {
        this.isInitialItem = true
      } else {
        this.isInitialItem = false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .form-buttons {
    position: relative;
    .btn:not(:last-child) {
      margin-right: (16/$base-font * 1rem);
    }
  }
</style>
