<template>
  <div id="accreditations-wrapper">
    <ButtonAutodesk class="btn--autodesk-step-back" label="Back to Supporting sources" @clicked="back"/>
    <ReportSuggested v-if="mobileView"  class="mobile"/>
    <h2>Accreditations</h2>
    <h6 class="small">Mention any sustainability awards or accreditations that feature your client’s company here.</h6>
    <form @submit.prevent="save" class='autodesk-form'>

      <div class="form-group">
        <TextArea 
          label="Title"
          name="source-title"
          v-model="title"
          :height="22"
          toolTip="Title of award or sustainability commitment organization (i.e. Business for Social Responsibility, CDP, Ceres, WBCSD, AIA 2030)"
        />
      </div>

      <div class="form-group">
        <Text name="url" 
          label="URL" 
          v-model="url"
          :maxCharachterCount="99"
          type="url"
          placeHolder="https://www.example.com"
          errorMessage="Please enter a valid URL"
        />
      </div>
      
      <FileUpload @deleteFile="deleteFile" @file-uploaded="fileUploaded" @file-error="fileError" :images="images"/>
      <div class="form-group">
        <TextArea 
          label="Additional Information" 
          v-model="additionalInformation"
          :height="222"
          toolTip="Description of commitment made, any leadership statements on involvement, project examples linked to commitment, etc.)"
        />
      </div>
      
      <div class='d-flex form-buttons'>
        <ButtonAutodesk class="btn btn--autodesk-outline" label="Save changes" @clicked="clickSave"/>
        <ButtonAutodesk class="btn btn--autodesk-text" label="Delete entry" @clicked="deleteItem"/>
        <Confirmation 
          title="Are you sure you want to delete this accreditation?" 
          positionClass="above" 
          @confirm="confirmDeleteItem()" 
          @cancel="cancelDelete" 
          v-if="showConfirmation === true" 
          :height="200"
          />
      </div>
      
    </form>
  </div>
</template>

<script>
  import validator from 'validator'
  import { axiosInstance, deleteItemImages, removeListItem, createNewAuditLogEntry } from '../../services/api'
  import FileUpload from '../forms/FileUpload'
  import Confirmation from '../Confirmation.vue'
  import TextArea from '../forms/TextArea'
  import Text from '../forms/Text'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import ReportSuggested from '../ReportSuggested'


  export default {
    name: 'ReportSupportingSources',
    components: {
      FileUpload,
      Confirmation,
      TextArea,
      Text,
      ButtonAutodesk,
      ReportSuggested
    },
    props: {
      stage: String,
    },
    data() {
      return {
        showConfirmation: '',
        isInitialItem: false
      }
    },
    methods: {
      async deleteFile (fileID) {
        // get the correct item in image array
        let imageArray = this.images.filter(function(img) {
          if (img.directus_files_id && img.directus_files_id !== null && img.directus_files_id.id !== fileID) {
            return true
          }
        })
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        //remove the file from the server
        try {
          axiosInstance.delete(`files/${fileID}`)
          createNewAuditLogEntry (this.$store.getters.reportID, 'Accreditation image deleted', false)
        } catch (error) {
          console.log('file deleting error')
        }
      },
      async fileUploaded (fileObject) {
        let imageArray = this.images
        imageArray = [{ directus_files_id: fileObject } , ...imageArray]
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        createNewAuditLogEntry(this.$store.getters.reportID, 'Accreditation image added', false)
      },
      fileError () {
        console.log('file error')
      },
      validateFields () {
        // assume all fields pass validation
        let validate = true
        let itemsInvalid = []
        this.reportLinkedItems.forEach(item => {
          const validateUrl = item.url && item.url.length ? validator.isURL(item.url) : true
          if (validateUrl === false) {
            validate = false
            itemsInvalid.push(item)
          }
          if (validate === false) {
          this.$notify({ 
            title: "Invalid fields - please check",
            group: 'custom-notification'
           })
        }
        })
        return validate
      },
      clickSave () {
        const validated = this.validateFields()
        if (validated) {
          this.saveSection()
        }
      },
      async saveSection () {
        const items = this.reportLinkedItems
        const reportID = this.$store.getters.reportID
        const sectionUpdate = {
          accreditations: items
        }
        try {
          // save the section ensuring related items are included 
          const response = await axiosInstance.patch(`items/reports/${reportID}`, sectionUpdate)
          console.log(response)
          this.$notify({  
            title: "Section saved",
            group: 'custom-notification'
           })
          try {
            // log initial item creation
            if (this.isInitialItem && this.title.length) {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Accreditation created', false)
              this.isInitialItem = false
            } else {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Accreditations section updated', this.stage, this.$store.getters.accreditationsHasUpdates)
            }
          } catch (error) {
            console.log('audit', error)
          }
          
          this.$store.commit('setAccreditationsHasUpdates', false)
          return true
        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
           })
        }
      },
      async saveItem (currentItem) {
        try { 
          let saveObject = {
            title: currentItem.title,
            url: currentItem.url,
            publication_date: currentItem.url,
            images: currentItem.images,
            additional_information: currentItem.additionalInformation
          }
          const response = await axiosInstance.patch(`items/financial_records/${currentItem.id}`, saveObject)
          console.log('response', response)
        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
          })
        }
      },
      nextSection () {
        this.saveSection()
        this.$router.push('supporting-sources')
      },
      back () {
        this.$router.push('supporting-sources')
      },
      deleteItem () {
        this.showConfirmation = true
      },
      confirmDeleteItem () {
        const item = this.activeItemObject
        // delete all linked images from item
        deleteItemImages(item)

        // delete the item only if more than one - otherwise clear it
        if (this.reportLinkedItems.length > 1) { 
          const updatedItemList = removeListItem(item, this.reportLinkedItems, 'supporting_sources')
          this.reportLinkedItems = updatedItemList
        } else {
          // set to default goal option
          this.activeItemObject.title = ''
          this.activeItemObject.url = ''
          this.activeItemObject.publication_date = ''
          this.activeItemObject.images = []
          this.activeItemObject.additional_information = ''
        }

        //save the section
        this.saveSection().then(() => {
          this.showConfirmation = false
        })
        createNewAuditLogEntry(this.$store.getters.reportID, 'Accreditation deleted')
      },
      cancelDelete () {
        this.showConfirmation = false
      }
    },
    computed: {
      mobileView: function () {
        return this.$store.getters.mobileView
      },
      // related item index relevant to selection we are on
      activeItemIndex: function () {
        return this.$store.getters.activeAccreditation
      },
      // related item relevant to selection we are on
      activeItemObject: function () {
        return this.reportLinkedItems[this.activeItemIndex]
      },
      // list of related items relevant to selection we are on
      reportLinkedItems: {
        get () {
          return this.$store.getters.reportAccreditations
        },
        set (value) {
          this.$store.commit('setReportAccreditations', value)
        }
      },

      images: {
        get () {
          try {
            return this.activeItemObject.images
          } catch (error) {
            console.log('try images error', error)
            return ''
          }
        },
        set (value) {
          try {
            const imagesChecked = value.filter(img => {
              if (img.directus_files_id && img.directus_files_id !== null) {
                return true
              }
            })
            let reportItems = this.reportLinkedItems
            reportItems[this.activeItemIndex].images = imagesChecked
            this.$store.commit('setReportAccreditations', reportItems)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      title: {
        get () {
          try {
            return this.activeItemObject.title
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportItems = this.reportLinkedItems
            reportItems[this.activeItemIndex].title = value
            this.$store.commit('setReportAccreditations', reportItems)
            this.$store.commit('setAccreditationsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      url: {
        get () {
          try {
            return this.activeItemObject.url
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportItems = this.reportLinkedItems
            reportItems[this.activeItemIndex].url = value
            this.$store.commit('setReportAccreditations', reportItems)
            this.$store.commit('setAccreditationsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      additionalInformation: {
        get () {
          try {
            return this.activeItemObject.additional_information
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportItems = this.reportLinkedItems
            reportItems[this.activeItemIndex].additional_information = value
            this.$store.commit('setReportAccreditations', reportItems)
            this.$store.commit('setAccreditationsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
    },
    mounted () {
      // as first items are initialized on report creation - extra check is needed when a item is selected/title changes on only child item
      if (this.reportLinkedItems.length <= 1 && (this.title === null || this.title === '')) {
        this.isInitialItem = true
      } else {
        this.isInitialItem = false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .form-buttons {
    position: relative;
    .btn:not(:last-child) {
        margin-right: (16/$base-font * 1rem);
    }
    
  }
</style>
