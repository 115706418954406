<template>
    <div id='login-page'>
      <div id="login">
        <h2>Login</h2>
        <form action=''>
          <input autocomplete="username" type="email" name="email" v-model="input.email" placeholder="Email" />
          <input autocomplete="current-password" id="current-password" type="password" name="password" v-model="input.password" placeholder="Password" />
          <button class="btn btn--autodesk-dark" type="button" v-on:keyup.enter="submit()" v-on:click="submit()">Login</button>
        </form>
        <div id='form-error' v-if="showError">
          <h4>{{ error }}</h4>
        </div>
    </div>
  </div>
    
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  // import { store } from '../store'
  export default {
    name: 'Login',
    data() {
      return {
        input: {
          email: "",
          password: "",
          mode: "cookie"
        },
        showError: false,
        error: "Inital Error"
      }
    },
    methods: {
      ...mapActions(['LogIn']),
      async submit () {
        if(this.input.email !== "" && this.input.password !== "") {
          await this.LogIn(this.input).then(() => {
            this.$router.push('/')
            this.showError = false
          }, error => {
            console.error("Authentication", error)
            this.error = "Incorrect username or password combination"
            this.showError = true
          })
        } else {
          this.showError = true
          this.error = "An email and password must be present"
        }
      }
    },
    computed: {
      ...mapGetters(['tealium']),
      isAuthenticated () {
        return this.$store.state.auth.loggedIn
      }
    },
    watch: { 
      isAuthenticated (newValue) {
        if (newValue === true) {
          this.$router.push({ name: 'Home' })
        }
      }
    },
    mounted () {
      /*
      if (this.isAuthenticated === true) {
        this.$router.push({ name: 'Home' })
      }
      */
    }
  }
</script>

<style scoped lang="scss">
  #login-page {
    width: 100%; 
    min-height: 100vh; 
    background: rgba(27, 41, 131, .8);
    display: flex;
  }
  
  #login {
    background-color: #fefefe;
    padding: 2em 3em 3em;
    border: 1px solid #888;
    margin: 10% auto auto;
    width: 80%;
    max-width: 600px;
    border-radius: .5rem;
    position: relative;
    position: relative;
    form {
      display: flex;
      flex-direction: column;
      width: 70%;
      input {
        border-radius: 3px; 
        border: 1px solid $color-8;
        background: #FFFFFF;
        padding: .3rem;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      button {
        
      }
    }

    #form-error {
      margin-top: .5rem;
    }
  }

  .logo {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 96px;
  }
</style>