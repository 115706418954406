import { createRouter, createWebHistory } from 'vue-router'
import { store } from '../store'
import { oauthUrl } from '../services/api'
import Home from '../views/Home.vue'
import PasswordResetRequest from '../views/PasswordResetRequest.vue'
import PasswordReset from '../views/PasswordReset.vue'
import Login from '../views/Login.vue'
import Report from '../views/Report'
import ErrorPage from '../views/ErrorPage'
import NotFound from '../views/NotFound'
import Search from '../views/Search'
import AuditLog from '../views/AuditLog'
import Auth from '../views/Auth'
import AuthFailed from '../views/AuthFailed'
import ReportOutput from '../views/ReportOutput'
import LogOut from '../views/LogOut'
import User from '../views/user'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true }
  },
  {
    path: '/logout',
    name: 'LogOut',
    component: LogOut,
    meta: { guest: true }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: { guest: true }
  },
  {
    path: '/auth-failed',
    name: 'AuthFailed',
    component: AuthFailed,
    meta: { guest: true }
  },
  {
    path: '/request-password-reset',
    name: 'PasswordResetRequest',
    component: PasswordResetRequest,
    meta: { guest: true }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: { guest: true }
  },
  {
    path: '/reports/:id/:stage',
    component: Report,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/reports/:id',
    component: Report,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/report-output/:id/:token',
    component: ReportOutput,
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: '/user',
    component: User,
    props: true,
    meta: { requiresAuth: false }
  },  
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    meta: { requiresAuth: true }
  },
  {
    path: '/error/:id',
    component: ErrorPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/search',
    props: true,
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/search/:query',
    props: true,
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-reports',
    props: true,
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-reports',
    props: true,
    component: Search,
    meta: { requiresAuth: true }
  },
  {
    path: '/audit-log',
    props: true,
    component: AuditLog,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAuthenticated = store.getters.isAuthenticated
    if (isAuthenticated === true) {
      next()
      return
    }
    // next('/login')
    window.location.href = oauthUrl
  } else {
    next()
  }
})

export default router
