<template>
  <div v-if="mappedEntries && mappedEntries.length" class='logs'>
    <div class='log-labels'>
      <div class='modified'>Last modified</div>
      <div v-if="type !== 'company'" class='report-name'>Report name</div>
      <div class='edited-by'>Edited by</div>
      <div class='action'>Action</div>
      <div class='author'>Author</div>
    </div>
    <ul v-if="mappedEntries.length" class='log-entries'>
      <li class="log-entry" v-for="log in mappedEntries" :key="log.id">
          <div class='modified cell'><span v-if="log.date_created">{{ log.date_created }}</span> <span class="created-time" v-if="log.time_created">{{ log.time_created }}</span> </div>
          <div v-if="type !== 'company'" class='report-name cell'>
            <span v-if="log.report && log.report.company_name" @click="openReport(log.report.id)">{{ log.report.company_name }}</span>
          </div>
          <div class='edited-by cell'><span v-if="log.user_created && log.user_created.first_name">{{ log.user_created.first_name }}</span> <span v-if="log.user_created && log.user_created.last_name">{{ log.user_created.last_name }}</span></div>
          <div class='action cell'><span v-if="log.action">{{ log.action }}</span></div>
          <div class='author cell'>
            <span v-if="log.report && log.report.user_created && log.report.user_created.first_name">{{ log.report.user_created.first_name }}</span> <span v-if="log.report && log.report.user_created && log.report.user_created.last_name">{{ log.report.user_created.last_name }}</span>
          </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { format } from 'date-fns'

  export default {
    name: 'AuditList',
    props: {
      logEntries: Array,
      type: String
    },
    methods: {
      openReport (reportID) {
        if(reportID) {
          const path = `/reports/${reportID}/report-overview`
          this.$router.push({ path: path })
        }
      }
    },
    computed: {
      mappedEntries () {
        // source from prop or store
        let entries = []
        if (this.type === 'company' && this.$store.getters.reportLogs) {
          entries = this.$store.getters.reportLogs
        } else if (!this.type || this.type !== 'company') { 
          entries = this.logEntries
        }
        if (entries.length > 0) {
          try {
            entries = entries.map(
            (logEntry) => {
              let dateTime = new Date(logEntry.date_created)
              if (dateTime && dateTime instanceof Date) {
                let date = format(dateTime, 'do MMM yyyy')
                let time = format(dateTime, 'hh:mm a')
                logEntry.date_created = date
                logEntry.time_created = time
              }
              return logEntry
            })
          } catch (error) {
            console.log('error mapping log entries', error)
          }
        }
        return entries
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
   .logs{
    .log-labels {
      display: flex;
      &> div {
          flex:1;
        }
    }
    .log-entries {
      box-shadow: inset 0px -1px 0px #666666, inset 0px 0px 0px 1px rgba(204, 204, 204, 0.5);
      border-bottom: 0;
      padding: 0;
      margin: 0 0 (48/$base-font)*1rem 0;
      border-radius: 4px;
      font-size: (14/$base-font)*1rem;
      .log-entry {
        border-bottom: 1px solid $color-16;
        display: flex;
        background-color: $color-26;
        &> div {
          border-right: 1px solid $color-16;
          flex: 1;
        }
        &:nth-child(even) {
          background-color: $color-25;
        }
        .cell {
          padding: (16/$base-font)*1rem (13/$base-font)*1rem 
        }
        &:last-child {
          border-bottom: 0px;
        }
        .report-name {
          span {
            border-bottom: 1px solid $brand-color-1;
            cursor: pointer;
          }
        }
        .created-time {
          color: $color-22;
        }
      }
    }
  }
</style>
