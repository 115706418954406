<template>
  <ul class="navbar-nav" :class="{ mobileView: mobileView }">
    <li class="nav-item nav__item menu-item">
      <router-link class="nav-link nav__item__link" :to="`/`">
        <span>Home</span>
      </router-link>
    </li>
    <li class="nav-item nav__item menu-item">
      <router-link
        class="nav-link nav__item__link"
        :to="`/my-reports`"
      >
        <span>My reports</span>
      </router-link>
    </li>
    <li class="nav-item nav__item menu-item">
      <router-link
        class="nav-link nav__item__link"
        :to="`/reports/create-report`"
      >
        <span>Create a report</span>
      </router-link>
    </li>
    <li class="nav-item nav__item menu-item">
      <router-link
        class="nav-link nav__item__link"
        :to="`/audit-log`"
      >
        <span>Audit log</span>
      </router-link>
    </li>
  </ul>
              
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MainNav',
    computed: {
    ...mapGetters(['mobileView'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar-nav.moblieView {

}
.nav-link {
  color: $color-1;
  margin: 0;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
