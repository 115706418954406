<template>
  <div class='page-wrapper'>
    <Header />
      <main class='container'>
        <div class='row'>
          <div class='col'>
            <h2>Page not found</h2>
          </div>
        </div>
      </main>
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header'
  import Footer from '../components/Footer'
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'NotFound',
    components: {
      Header,
      Footer
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  
</style>