<template>
  <div class='page-wrapper'>
    <!-- <component :is="'script'" :src="tealium" type="application/javascript"></component> -->
    <Header>
      <ReportNav v-if="id !== 'create-report'" :stage="stage"/>
    </Header>

    <Confirmation 
      title="Are you sure you want to delete this report?" 
      @confirm="confirmDeleteReport()" 
      @cancel="cancelDeleteReport" 
      v-if="showDeleteConfirmation"
    />
    <div class='detail-mobile' v-if="mobileView">
      <div class='container'>
        <ReportDetail v-if="mobileView && id !== 'create-report'" @deleteReport="deleteReport" :stage="stage" :mobileView="mobileView"/>
      </div>
    </div>

    <div class='container'>
      <div class='row'>
        <aside v-if="stage !== 'report-overview' && id !== 'create-report'" id="items-column" class='col-sm-4 col-xl-3'>
          <ReportButtonList v-if="stage === 'company'" :stage="stage" @scrollToItem="scrollToItem" listTitle="Sustainability goal list"/>
          <ReportButtonList v-if="stage === 'contacts'" :stage="stage" @scrollToItem="scrollToItem" listTitle="Contact list"/>
          <ReportButtonList v-if="stage === 'financial-data'" :stage="stage" @scrollToItem="scrollToItem" listTitle="Financial data"/>
          <ReportButtonList v-if="stage === 'supporting-sources'" :stage="stage" @scrollToItem="scrollToItem" listTitle="Supporting sources"/>
          <ReportButtonList v-if="stage === 'accreditations'" :stage="stage" @scrollToItem="scrollToItem" listTitle="Accreditations"/>
        </aside>
        <main v-if="stage === 'report-overview' || id === 'create-report'"  id="report-main" class='offset-sm-1 offset-xl-3 col-sm-10 col-xl-5'>
          <div ref="reportForm"  id='reportForm'>
            <ReportOverview :reportID="id" :stage="stage"/>
          </div>
        </main>
        <main v-else id="report-main" class='col-sm-8 col-xl-5'>
          <div ref="reportForm"  id='reportForm'>
            <ReportCompany v-if="stage === 'company'" :stage="stage"/>
            <ReportGoal v-if="stage === 'company'" id="activeItem" :stage="stage"/>
            <ReportContacts v-if="stage === 'contacts'" id="activeItem" :stage="stage"/>
            <ReportFinancialData v-if="stage === 'financial-data'" id="activeItem" :stage="stage"/>
            <ReportSupportingSources v-if="stage === 'supporting-sources'" id="activeItem" :stage="stage"/>
            <ReportAccreditations v-if="stage === 'accreditations'" id="activeItem" :stage="stage"/>
          </div>

        </main>
        <div id='separator-col' class='col-sm-none col-xl-1'>
          <div id='separator'></div>
        </div>
        <aside id="details-column" class='col-md-none col-xl-3'>
          <ReportDetail v-if="!mobileView" @deleteReport="deleteReport" :stage="stage" :id="id" :mobileView="mobileView"/>
          <ReportSuggested v-if="!mobileView && id !== 'create-report'"/>
        </aside>
      </div>
    </div>
    <ReportNotes v-if="stage === 'report-overview'"/>
    <Footer />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { fetchFullReport, deleteItemImages, deleteItemApi, fetchOptions } from '../services/api'
  import { getCookie } from '../services/helpers'
  
  import Footer from '../components/Footer'
  import Header from '../components/Header'
  import ReportNav from '../components/report/ReportNav'
  import ReportOverview from '../components/report/ReportOverview'
  import ReportNotes from '../components/report/ReportNotes'
  import ReportCompany from '../components/report/ReportCompany'
  import ReportContacts from '../components/report/ReportContacts'
  import ReportFinancialData from '../components/report/ReportFinancialData'
  import ReportSupportingSources from '../components/report/ReportSupportingSources'
  import ReportAccreditations from '../components/report/ReportAccreditations'
  import ReportButtonList from '../components/report/ReportButtonList'
  import ReportGoal from '../components/report/ReportGoal'
  import Confirmation from '../components/Confirmation'
  import ReportDetail from '../components/ReportDetail'
  import ReportSuggested from '../components/ReportSuggested'

  export default {
    name: 'Report',
    props: ['id', 'stage'],
    components: {
      Footer,
      Header,
      ReportNav,
      ReportOverview,
      ReportNotes,
      ReportCompany,
      ReportContacts,
      ReportFinancialData,
      ReportSupportingSources,
      ReportAccreditations,
      ReportButtonList,
      ReportGoal,
      Confirmation,
      ReportDetail,
      ReportSuggested
    },
    data() {
      return {
        report: {},
        regions: [],
        showDeleteConfirmation: false
      };
    },
    methods: {
      ...mapActions(['setReport']),
      async fetchReport () {
        try {
          fetchFullReport(this.id).then((report) => {
            if (report && report.id){
              this.setReport(report)
            } else {
              this.$router.push('/not-found')
            }
          })
        } catch (error) {
          console.log('get report error', error)
        }
      },
      scrollToItem () {
        document.getElementById('activeItem').scrollIntoView({
          behavior: "smooth"
        })
      },
      deleteReport () {
        this.showDeleteConfirmation = true
      },
      confirmDeleteReport () {
        const linkedCollections = [
          { items: this.reportSustainabilityGoals, collectionName: 'sustainability_goals' },
          { items: this.reportContacts, collectionName: 'contacts' },
          { items: this.reportFinancialRecords, collectionName: 'financial_records' },
          { items: this.reportSupportingSources, collectionName: 'supporting_sources' },
          { items: this.reportAccreditations, collectionName: 'accreditations' },
          { items: this.reportOverviewNotes, collectionName: 'overview_notes' },
        ]
        linkedCollections.forEach(collection => {
          // loop through and delete all goals
          collection.items.forEach(item => {
            try {
              deleteItemImages(item)
              deleteItemApi(item.id, collection.collectionName)
            } catch (e) {
              console.log('report deleting item error', e)
            }
          })
        });
        
        deleteItemApi(this.id, 'reports')
        this.showDeleteConfirmation = false
        this.$notify({  
          title: "Report deleted",
          group: 'custom-notification'
        })
        this.$router.push('/')

      },
      cancelDeleteReport () {
        this.showDeleteConfirmation = false
      },
      setReportPosition () {
        const width = this.$refs.reportForm.getBoundingClientRect().width
        const left = this.$refs.reportForm.getBoundingClientRect().left
        this.$store.commit('setNotificationPosition', left)
        this.$store.commit('setNotificationWidth', width)
      }
    },
    computed: {
      ...mapGetters([
        'mobileView',
        'reportSustainabilityGoals',
        'reportContacts',
        'reportFinancialRecords',
        'reportSupportingSources',
        'reportAccreditations',
        'reportOverviewNotes',
        'tealium'
      ])
    },
    updated () {
      this.setReportPosition()
    },
    created () {
      fetchOptions()
      if (this.id !== 'create-report') {
        // if this a reload from oauth error we don't reload the report - it should be in local storage 
        const returnLocation = getCookie('oauth_return_location')
        if (!returnLocation || returnLocation.length <= 1) {
          this.fetchReport()
        }
      } else {
        this.report = {}
      }
    },
    mounted () {
      this.setReportPosition()
      window.addEventListener('resize', this.setReportPosition)
    }
  }
</script>

<style lang="scss">
  .page-wrapper {
    position: relative;
    .detail-mobile {
      background: $color-1;
      border-bottom: 1px solid $color-16;
    }
  }
  #activeItem {
    position: relative;
  }
  #report-main {
    margin-top: 6.25rem;
  }
  #separator-col {
    padding-top: (100/$base-font) * 1rem; 
    padding-bottom: (100/$base-font) * 1rem; 
  }
  #separator {
    width: 50%;
    height: 100%;
    border-right: 1px solid $color-16;
  }
  aside {
    padding-top: (100/$base-font) * 1rem; 
    padding-bottom: (100/$base-font) * 1rem; 
    &#items-column {
      padding-right: 1.5rem;
    }
    &#details-column {

    }
  }

</style>