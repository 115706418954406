<template>
  <div>
    <ButtonAutodesk class="btn--autodesk-step-back" label="Back to The company" @clicked="back"/>
    <ReportSuggested v-if="mobileView"  class="mobile"/>
    <h2>Contacts</h2>
    <h6 class="small">Include contacts involved with sustainability initiatives and innovations within your client’s company here.</h6>
    <form @submit.prevent="save" class='autodesk-form'>

      <div class="form-group">
        <Text name="contact-name" 
          label="Name" 
          v-model="contactName"
          :maxCharachterCount="99"
        />
      </div>
      <div class="form-group">
        <Text name="contact-name" 
          label="Job title" 
          v-model="jobTitle"
          :maxCharachterCount="99"
        />
      </div>
      <div class="form-group">
        <Text name="telephone-number" 
          label="Telephone Number" 
          v-model="telephoneNumber"
          :maxCharachterCount="99"
        />
      </div>
      <div class="form-group">
        <Text name="email" 
          label="Email" 
          v-model="email"
          :maxCharachterCount="99"
          type="email"
          placeHolder="eaxmple@example.com"
          errorMessage="Please enter a valid email"
        />
      </div>
      <div class="form-group">
        <Text name="linkedin-profile" 
          label="LinkedIn profile" 
          v-model="linkedinProfile"
          :maxCharachterCount="99"
          type="url"
          placeHolder="https://www.linkedin.com/in/example"
          errorMessage="Please enter a valid URL"
        />
      </div>
      <div class="form-group">
        <Date name="last-contacted"
          label="Last contacted"
          v-model="lastContacted"
          inputFormat="do MMM yyyy"
          />
      </div>
      <FileUpload @deleteFile="deleteFile" @file-uploaded="fileUploaded" @file-error="fileError" :images="images"/>
      <div class="form-group">
        <TextArea 
            label="Additional Information" 
            v-model="additionalInformation"
            :height="22"
            toolTip="Detail from last conversation, other Autodesk contacts with relationship to the contact, etc."
          />
      </div>
      <div class='d-flex form-buttons'>
        <ButtonAutodesk class="btn btn--autodesk-outline" label="Save changes" @clicked="clickSave"/>
        <ButtonAutodesk class="btn btn--autodesk-text" label="Delete entry" @clicked="deleteItem"/>
        <ButtonAutodesk class="btn btn--autodesk-dark ms-auto" label="Next" @clicked="nextSection"/>
        <Confirmation 
          title="Are you sure you want to delete this goal?" 
          positionClass="above" 
          @confirm="confirmDeleteItem()" 
          @cancel="cancelDelete" 
          v-if="showConfirmation === true" 
          :height="200"
          />
      </div>
      
    </form>
  </div>
</template>

<script>
  import { axiosInstance, deleteItemImages, removeListItem, createNewAuditLogEntry} from '../../services/api'
  import validator from 'validator'
  import FileUpload from '../forms/FileUpload'
  import Confirmation from '../Confirmation.vue'
  import TextArea from '../forms/TextArea'
  import Text from '../forms/Text'
  import Date from '../forms/Date'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import ReportSuggested from '../ReportSuggested'

  export default {
    name: 'ReportContacts',
    components: {
      FileUpload,
      Confirmation,
      TextArea,
      Text,
      Date,
      ButtonAutodesk,
      ReportSuggested
    },
    props: {
      stage: String,
    },
    data() {
      return {
        showConfirmation: '',
        isInitialItem: false
      };
    },
    methods: {
      async deleteFile (fileID) {
        // get the correct item in image array
        let imageArray = this.images.filter(function(img) {
          if (img.directus_files_id && img.directus_files_id !== null && img.directus_files_id.id !== fileID) {
            return true
          }
        })
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        //remove the file from the server
        try {
          axiosInstance.delete(`files/${fileID}`)
          createNewAuditLogEntry(this.$store.getters.reportID, 'Contact image deleted', false)
        } catch (error) {
          console.log('file deleting error')
        }
      },

      async fileUploaded (fileObject) {
        let imageArray = this.images
        imageArray = [{ directus_files_id: fileObject } , ...imageArray]
        this.images = imageArray
        this.saveItem(this.activeItemObject)
        createNewAuditLogEntry(this.$store.getters.reportID, 'Contact image added', false)
      },

      fileError () {
        console.log('file error')
      },
      validateFields () {
        // assume all fields pass validation
        let validate = true
        let itemsInvalid = []
        this.reportLinkedItems.forEach(item => {
          const validateUrl = item.linkedin_profile && item.linkedin_profile.length ? validator.isURL(item.linkedin_profile) : true
          const validateEmail = item.email && item.email.length ? validator.isEmail(item.email) : true
          if (validateUrl === false || validateEmail ===false) {
            validate = false
            itemsInvalid.push(item)
          }
        })
        if (validate === false) {
          this.$notify({ 
            title: "Invalid fields - please check",
            group: 'custom-notification'
           })
        }
        return validate
      },
      clickSave () {
        const validated = this.validateFields()
        if (validated) {
          this.saveSection()
        }
      },
      async saveSection () {
        const contacts = this.reportLinkedItems
        const reportID = this.$store.getters.reportID
        const sectionUpdate = {
          contacts: contacts
        }
        try {
          // save the section ensuring related items are included 
          const response = await axiosInstance.patch(`items/reports/${reportID}`, sectionUpdate)
          console.log(response)
          this.$notify({  
            title: "Section saved",
            group: 'custom-notification'
           })
          try {
            // log initial item creation
            if (this.isInitialItem && this.contactName.length) {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Contact created', false)
              this.isInitialItem = false
            } else {
              createNewAuditLogEntry(this.$store.getters.reportID, 'Contacts section updated', this.stage, this.$store.getters.contactsHasUpdates)
            }
          } catch (error) {
            console.log('audit', error)
          }
          
          this.$store.commit('setContactsHasUpdates', false)
          return true

        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
           })
        }
      },
      async saveItem (currentItem) {
        try { 
          let saveObject = {
            name: currentItem.name,
            job_title: currentItem.jobTitle,
            images: currentItem.images,
            telephone_number: currentItem.telephoneNumber,
            email: currentItem.email,
            linkedin_profile: currentItem.linkedinProfile,
            additional_information: currentItem.additionalInformation,
            last_contacted: currentItem.lastContacted
          }
          const response = await axiosInstance.patch(`items/contacts/${currentItem.id}`, saveObject)
          console.log('response', response)
        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "Save Failed",
            group: 'custom-notification'
          })
        }
      },
      nextSection () {
        const save = this.validateFields()
        if (save) {
          this.saveSection()
          this.$router.push('financial-data')
        }
      },
      back () {
        this.$router.push('company')
      },
      deleteItem () {
        this.showConfirmation = true
      },
      confirmDeleteItem () {
        const item = this.activeItemObject
        // delete all linked images from item
        deleteItemImages(item)

        // delete the item only if more than one - otherwise clear it
        if (this.reportLinkedItems.length > 1) { 
          const updatedItemList = removeListItem(item, this.reportLinkedItems, 'contacts')
          this.reportLinkedItems = updatedItemList
        } else {
          // set to default goal option
          this.activeItemObject.name = ''
          this.activeItemObject.job_title = ''
          this.activeItemObject.telephone_number = ''
          this.activeItemObject.email = ''
          this.activeItemObject.linkedin_profile = ''
          this.activeItemObject.last_contacted = ''
          this.activeItemObject.images = []
          this.activeItemObject.additional_information = ''
        }

        //save the section
        this.saveSection().then(() => {
          this.showConfirmation = false
        })
        createNewAuditLogEntry(this.$store.getters.reportID, 'Contact deleted')
      },
      cancelDelete () {
        this.showConfirmation = false
      }
    },
    computed: {
      mobileView: function () {
        return this.$store.getters.mobileView
      },
      // related item index relevant to selection we are on
      activeItemIndex: function () {
        return this.$store.getters.activeContact
      },
      // related item relevant to selection we are on
      activeItemObject: function () {
        return this.reportLinkedItems[this.activeItemIndex]
      },
      // list of related items relevant to selection we are on
      reportLinkedItems: {
        get () {
          return this.$store.getters.reportContacts
        },
        set (value) {
          this.$store.commit('setReportContacts', value)
        }
      },

      images: {
        get () {
          try {
            return this.activeItemObject.images
          } catch (error) {
            console.log('try images error', error)
            return ''
          }
        },
        set (value) {
          try {
            const imagesChecked = value.filter(img => {
              if (img.directus_files_id && img.directus_files_id !== null) {
                return true
              }
            })
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].images = imagesChecked
            this.$store.commit('setReportContacts', reportContacts)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      contactName: {
        get () {
          try {
            return this.activeItemObject.name
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].name = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      jobTitle: {
        get () {
          try {
            return this.activeItemObject.job_title
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].job_title = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      telephoneNumber: {
        get () {
          try {
            return this.activeItemObject.telephone_number
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].telephone_number = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      email: {
        get () {
          try {
            return this.activeItemObject.email
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].email = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      linkedinProfile: {
        get () {
          try {
            return this.activeItemObject.linkedin_profile
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].linkedin_profile = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      lastContacted: {
        get () {
          try {

            let date = this.activeItemObject.last_contacted
            console.log('parent get date', date)
            return date
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].last_contacted = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
      additionalInformation: {
        get () {
          try {
            return this.activeItemObject.additional_information
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        },
        set (value) {
          try {
            let reportContacts = this.reportLinkedItems
            reportContacts[this.activeItemIndex].additional_information = value
            this.$store.commit('setReportContacts', reportContacts)
            this.$store.commit('setContactsHasUpdates', true)
          } catch (error) {
            console.log('try error', error)
            return ''
          }
        }
      },
    },
    mounted () {
      // as first items are initialized on report creation - extra check is needed when a item is selected/title changes on only child item
      if (this.reportLinkedItems.length <= 1 && (this.contactName === null || this.contactName === '')) {
        this.isInitialItem = true
      } else {
        this.isInitialItem = false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  #contacts-wrapper {
    position: relative;
  }

  .form-buttons {
    position: relative;
    .btn:not(:last-child) {
        margin-right: (16/$base-font * 1rem);
    }
    
  }
</style>
