<template>
  <div class="page-wrapper">
    <Header />
    <main class="container">
      <div class="row">
        <div class="col">
          <h2 v-if="$route.path === '/my-reports'">
            Reports for {{ $store.getters.user.first_name }}
            {{ $store.getters.user.last_name }}
          </h2>
          <h2 v-else-if="query && query.length">Search results for “{{ query }}”</h2>
          <h2 v-else>All reports</h2>
        </div>
      </div>
      <div class="filter">
        <form @submit.prevent="save" class='sustainability-goal autodesk-form'>
          <div class='row filter-by-row'>
            <div class="filter-by-col mt-4">Filter by:</div>
          </div>
          <div class="row">
            <div class='filters-col'>
              <div v-if="$route.path.includes('search') || $route.path.includes('all-reports')" class="search-col">
                <div class="form-group">
                  <Select 
                    label="Author" 
                    name="author" 
                    :multiple="false" 
                    placeholder="Type to filter"
                    v-model="filterSelected.user_created"
                    @search="fetchUsers"
                    @click="fetchUsers"
                    :options="userOptions" />
                </div>
              </div>
              <div class="search-col">
                <div class="form-group">
                  <Select label="Modified by" name="modified-by" :multiple="false" placeholder="Select date" v-model="filterSelected.date_created" :options="dateOptions"/>
                </div>
              </div>
              <div class="search-col">
                <div class="form-group">
                  <Select label="Sustainability goal" name="sustainability-goal" :multiple="true" :stripFirstWord="true" :firstIsPlaceholder="true" v-model="filterSelected.sustainability_goal" :options="goalList"/>
                </div>
              </div>
              <div class="search-col">
                <div class="form-group">
                  <Select label="Region" name="region" :multiple="true" :firstIsPlaceholder="true" v-model="filterSelected.region" :options="$store.getters.regions"/>
                </div>
              </div>
              <div class="search-col">
                <div class="form-group">
                  <Select label="Industry sector" name="industry-sector" :multiple="true" :firstIsPlaceholder="true" v-model="filterSelected.industry_sector" :options="$store.getters.industrySectors"/>
                </div>
              </div>
              <div class="search-col filter-buttons">
                <ButtonAutodesk class="btn mt-4 btn--autodesk-dark apply" label="Apply" @clicked="reportSearch"/>
                <ButtonAutodesk class="btn mt-4 btn--autodesk-cancel reset" label="Reset" @clicked="resetFilters"/>
              </div>
            </div>
          </div>
        </form>
        
      </div>
      <div class="row cards">
        <div class="col">
          <div id="my-latest-reports">
            <h5 v-if="$route.path === '/my-reports'">My latest reports</h5>
            <div class="row">
              <reportCard
                v-for="report in myLatestReports"
                :key="report.id"
                :report="report"
                tag="h4"
                col="3"
              />
            </div>
          </div>
        </div>
      </div>
      <Pagination :page="page" :perPage="perPage" :items="items" @pageChange="setPage" />
      <div class='row create-new'>
        <div class='col'>
          <h3>Can’t find what you’re looking for? </h3>
          <ButtonAutodesk class="btn btn--autodesk-dark" label="Create a new report" @clicked="createReport"/>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/Footer'
import Header from '../components/Header'
import reportCard from '../components/reportCard'
import Pagination from '@/components/Pagination.vue'
import Select from '@/components/forms/Select.vue'
import ButtonAutodesk from '../components/buttons/ButtonAutodesk'
import { axiosInstance, fetchOptions } from '../services/api'
import { mapGetters } from 'vuex'

export default {
  name: 'Search',
  props: ['query'],
  components: {
    Header,
    Footer,
    reportCard,
    Pagination,
    Select,
    ButtonAutodesk
  },
  data() {
    return {
      myLatestReports: [],
      page: 1,
      perPage: 12,
      items: '',
      fields:
        'company_name,id,date_created,date_updated,industry_sector.*,sustainability_goals.*,region.label,short_description,industry_sector,user_created.first_name,user_created.last_name,user_updated.first_name,user_updated.last_name,meta',
      filterSelected: {
        user_created: '',
        date_created: '',
        sustainability_goal: [],
        region: [],
        industry_sector: [],
      },
      dateOptions: [{ label: '1 week' }, { label: '1 month' }, { label: '1 year' }, { label: 'More then 1 year' }],
      userOptions: '',
      userID: '',
    }
  },
  methods: {
    createReport() {
      this.$router.push(`/reports/create-report`)
    },
    setPage(payload) {
      this.page = payload.page
      this.reportSearch()
    },
    async fetchUsers(search) {
      let userArray
      //let idArray
      
      if (search.length) { 
        search =`&[user_created]='${search}`
      }
      try {
        let apiCall = ''
        // fetch all users within the search
        if (this.query && this.query.length) {
          apiCall = `items/reports?filter[company_name][_contains]=${this.query}&fields=*.*.*${search}`
        } else {
          apiCall = `items/reports?fields=*.*.*${search}`
        }
        let first_call = await axiosInstance.get(apiCall)
        let reports = first_call.data.data
        userArray = reports.map((report) => {
          let user = {}
          user.label = report.user_created.first_name + ' ' + report.user_created.last_name
          user.id = report.user_created.id
          return user
        })
        // dedupe array of users
        userArray = userArray.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.label === value.label
          ))
        )
        this.userOptions = [...new Set(userArray)]
      } catch (error) {
        console.log(error)
      }
      
    },
    async reportSearch() {
      let fullApiCall = ''
      let userFilter = ''
      let searchFilter = ''
      let fields = this.fields

      if (this.query && this.query.length) {
        searchFilter = `&filter[company_name][_contains]=${this.query}`
      }
      if (this.$route.path === '/my-reports') {
        userFilter = `&filter[user_created][_eq]=${this.$store.getters.user.id}`
      }

      fullApiCall = `items/reports?fields=${fields}${this.apiCall}${userFilter}${searchFilter}&meta=filter_count&offset=${this.setOffset}&limit=${this.perPage}`

      try {
        let first_call = await axiosInstance.get(fullApiCall)
        this.myLatestReports = first_call.data.data
        this.items = first_call.data.meta.filter_count
      } catch (error) {
        console.log(error)
      }
    },
    resetFilters () {
      this.filterSelected = {
        user_created: '',
        date_created: '',
        sustainability_goal: [],
        region: [],
        industy_sector: [],
      }
      this.page = 1
      this.reportSearch()
    }
  },
  computed: {
    ...mapGetters(['tealium']),
    goalList () {
      let goals = []
      if (this.$store.getters.sustainabilityGoalOptions && this.$store.getters.sustainabilityGoalOptions.length) {
        goals = this.$store.getters.sustainabilityGoalOptions.map((goal) => {
          goal.label = goal.number + '. ' + goal.label
          return goal
        })
      }
      return goals
    },
    setOffset(offset) {
      if (this.page === 1) {
        offset = 0
      } else {
        offset = this.page * this.perPage - this.perPage
      }
      return offset
    },
    filterUser() {
      let userFilter = ''
      if (this.filterSelected.user_created && this.filterSelected.user_created.id) {
        userFilter = '&filter[user_created]=' + this.filterSelected.user_created.id
      }
      return userFilter
    },
    filterRegion () {
      let regionArray = []
      let regionFilter = ''
      if (this.filterSelected.region && this.filterSelected.region.length) {
        regionArray = this.filterSelected.region.map((region) => {
          return region.id
        })
        regionFilter = '&filter[region][_in]=' + regionArray
      }
      return regionFilter
    },
    filterSector() {
      let sectorArray = []
      let sectorFilter = ''
      if (this.filterSelected.industry_sector && this.filterSelected.industry_sector.length) {
        sectorArray = this.filterSelected.industry_sector.map((sector) => {
          return sector.id
        })
        sectorFilter = '&filter[industry_sector][_in]=' + sectorArray
      }
      return sectorFilter
    },
    filterSusGoal () {
      let goalArray = []
      let goalFilter = ''
      if (this.filterSelected.sustainability_goal && this.filterSelected.sustainability_goal.length) {
        goalArray = this.filterSelected.sustainability_goal.map((goal) => {
          return goal.id
        })
        goalFilter = '&filter[sustainability_goals][sustainability_goal][_in]=' + goalArray
      }
      return goalFilter
    },
    filterDate () {
      let date, dateFilter
      const dateOption = this.filterSelected.date_created
      if (dateOption && dateOption.label === '1 week') {
        date = new Date(
          new Date().getTime() - 7 * 24 * 60 * 60 * 1000,
        ).toISOString()
        dateFilter = '&filter[date_created][_gte]='
      } else if (dateOption && dateOption.label === '1 month') {
        date = new Date(
          new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
        ).toISOString()
        dateFilter = '&filter[date_created][_gte]='
      } else if (dateOption && dateOption.label && dateOption.label.includes('year')) {
        date = new Date(
          new Date().getTime() - 365 * 24 * 60 * 60 * 1000,
        ).toISOString()
        dateFilter = '&filter[date_created][_gte]='
      } else {
        date = ''
        dateFilter = ''
      }
      return dateFilter + date
    },
    apiCall() {
      return (
        this.filterUser +
        this.filterRegion +
        this.filterSector +
        this.filterSusGoal +
        this.filterDate
      )
    }
  },
  created() {
    this.reportSearch()
    fetchOptions()
  },
}
</script>

<style scoped lang="scss">

  .filter{
    .row.filter-by-row {
      padding-bottom: .5rem;
    }
    .row {
      flex-wrap: nowrap;
      padding-bottom: 26px;
      flex: 1;
      .filter-by-col {
        flex: 0 1 80px;
        padding-right: 0;
      }
      .filters-col {
        padding-left: 0;
        display: flex;
        flex: 1;
        .search-col {
          flex: 1;
          margin: 0 0 0 1.5rem;
          &:first-child {
            margin-left: .75rem;
          }
          &.filter-buttons {
            flex: 0 0 195px;
            .reset {
              margin-left: .5rem;
            }
          }
        }
      }

      
      @media screen and (max-width: 1200px) { 
        .filters-col {
          flex-wrap: wrap;
            .search-col {
            // min-width: 250px;
            flex-basis: calc(33% - 1.5rem);
            flex-grow: 0;
            &:nth-child(4) {
              margin-left: .75rem;
            }
            &.filter-buttons  {
              flex-basis: calc(33% - 1.5rem);
              margin-right: auto;
              .apply {

              }
              .reset {
                margin-left: 1rem;
              }
            }
          }
        }
      }
    }
  }

  .create-new {
    .col {
      text-align: center;
    }
    h3 {
      margin: 0 auto;
      margin-top: (88/$base-font)*1rem;
    }
    button {
      margin: 0 auto;
      margin-top: (24/$base-font)*1rem;
    }
  }
</style>
