<template>
  <div>
    <h2>Report Overview</h2>
    <h6 class="small">Name your report and make it searchable so you and your colleagues can easily find it later.</h6>
      <form @submit.prevent="save" class='report-overview autodesk-form'>
        <div class="form-group">
          <Text name="report-overview" 
            label="Company name" 
            v-model="companyName"
            :maxCharachterCount="45"
            :showCount="true"
            toolTip="Full Business Name from Salesforce"
          />
        </div>
        <div class="form-group">
          <TextArea 
            label="Short Description" 
            v-model="shortDescription"
            :maxCharachterCount="100"
            :height="80"
            :showCount="true"
          />
        </div>
        <div class="row">
          <div class='col'>
            <div class="form-group">
              <Select label="Region" name="region" :isClearable="false" :multiple="false" :firstIsPlaceholder="true" v-model="reportRegion" :options="regions"/>
              <!-- <v-select :options="regions" v-model="reportRegion"></v-select> -->
            </div>
          </div>
          <div class='col'>
            <div class="form-group">
              <Select label="Industry sector" name="industry-sector" :isClearable="false" :multiple="false" :firstIsPlaceholder="true" v-model="reportIndustry" :options="industrySectors"/>
              <!-- <v-select :options="industrySectors" v-model="reportIndustry"></v-select> -->
            </div>
          </div>
        </div>
      <div class='d-flex form-buttons'>
        <ButtonAutodesk class="btn btn--autodesk-dark ms-auto" label="Next" @clicked="nextSection"/>
      </div>
    </form>
  </div>
</template>

<script>
/* import vSelect from 'vue-select' */
  import { axiosInstance, createReport, createNewAuditLogEntry } from '../../services/api'
  import { mapActions } from 'vuex'
  import TextArea from '../forms/TextArea'
  import Text from '../forms/Text'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import Select from '../forms/Select'

  export default {
    name: 'ReportOverview',
    props: {
      reportID: null,
      stage: String,
    },
    components: {
      TextArea,
      Text,
      ButtonAutodesk,
      Select
    },
    data() {
      return {
        test: '',
        sectionUpdated: false
      }
    },
    methods: {
      ...mapActions(['setReport', 'setReportSustainabilityGoals', 'setOverviewHasUpdates']),
      async nextSection () {
        if (this.companyName.length) {
          let updateID = this.reportID
          let reportObject = {
            company_name: this.$store.getters.companyName,
            short_description: this.$store.getters.shortDescription,
          }
          if (this.$store.getters.reportRegion && Object.keys(this.$store.getters.reportRegion).length !== 0) {
            reportObject.region = this.$store.getters.reportRegion
          }
          if (this.$store.getters.reportIndustry && Object.keys(this.$store.getters.reportIndustry).length !== 0) {
            reportObject.industry_sector = this.$store.getters.reportIndustry
          }

          if (this.reportID === 'create-report') {
            // save the report
            try {
              const newReport = await createReport(reportObject)
              this.setReport(newReport)
              // try and make a new one hwere
              createNewAuditLogEntry(newReport.id, 'Report created', 'report-overview', true)
              this.$router.push(`/reports/${newReport.id}/company`)
              this.$notify({
                title: "Report created",
                group: 'custom-notification'
              })
              
            } catch (error) {
              console.log(error.response);
              this.$notify({
                title: "File failed to create report",
                group: 'custom-notification'
              })
            }
          } else {
            // update the report
            try {
              axiosInstance.patch(`items/reports/${updateID}`, reportObject).then((response) => {
                console.log('report updated', response)
                createNewAuditLogEntry (updateID, 'Report overview updated', this.stage, this.$store.getters.overviewHasUpdates)
                this.$router.push(`/reports/${updateID}/company`)
                this.$notify({
                  title: "Report updated",
                  group: 'custom-notification'
                })
              })
            } catch (error) {
              console.log(error.response);
              this.$notify({ 
                title: "File failed to update report",
                group: 'custom-notification'
              })
            }
          }
        } else {
          this.$notify({ 
            title: "Please set the company name",
            group: 'custom-notification'
          })
        }
      },
      clearReport () {
        this.setReport('clear')
      }
    },
    computed: {
      regions: function () {
        return this.$store.getters.regions
      },
      industrySectors: function () {
        return this.$store.getters.industrySectors
      },
      reportRegion: {
        get () {
          let region = this.$store.getters.regions[0]
          if (this.$store.getters.reportRegion && Object.keys(this.$store.getters.reportRegion).length !== 0) {
            region = this.$store.getters.reportRegion
          }
          return region
        },
        set (value) {
          this.$store.commit('setReportRegion', value)
        }
      },
      reportIndustry: {
        get () {
          let industry = this.$store.getters.industrySectors[0]
          if (this.$store.getters.reportIndustry && Object.keys(this.$store.getters.reportIndustry).length !== 0) {
            industry = this.$store.getters.reportIndustry
          }
          return industry
        },
        set (value) {
          this.$store.commit('setReportIndustry', value)
        }
      },
      companyName: {
        get () {
          return this.$store.getters.companyName
        },
        set (value) {
          this.$store.commit('setCompanyName', value)
        }
      },
      shortDescription: {
        get () {
          return this.$store.getters.shortDescription
        },
        set (value) {
          this.$store.commit('setShortDescription', value)
        }
      }
    },
    created () {
      if (this.reportID === 'create-report') {
        this.clearReport()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
