<template>
  <div class="userIcon" @click="showDrop=!showDrop">
    <span v-if="initial" class="letters">
      <span v-for="(letter, index) in initial" :key="index">
      {{ letter }}
      </span>
    </span>
    <DropDown 
        :firstName="firstName"
        :lastName="lastName"
        :email="email"
        @hide-drop="showDrop = false"
        v-if="showDrop" :user="true"/>
        
    </div>
</template>

<script>
import DropDown from './DropDown'

export default {
  name: 'Header',
  components: {
    DropDown
  },
  data() {
    return {
      initial: '',
      showDrop: false,
    }
  },
  methods: {
    initials () {
      const first = this.firstName && this.firstName.length ? this.firstName.slice(0, 1) : ''
      const second = this.lastName && this.lastName.length ? this.lastName.slice(0, 1) : ''
      this.initial = { first, second }
    },
  },
  created () {
    this.initials()
  },
  computed: {
    firstName: function () {
      return this.$store.getters.user.first_name
    },
    lastName: function () {
      return this.$store.getters.user.last_name
    },
    email: function () {
      return this.$store.getters.user.email
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.userIcon {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 999px;
  background: $color-17;
  font-family: 'ArtifaktElement-Bold';
  font-size: (14/$base-font)*1rem;
  color: $color-1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  .letters {
   cursor: pointer;
  }
}
</style>
