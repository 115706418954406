<template>
  <div id='file-wrapper'>
    <div class="file-field">
      <label>Images <span><span v-for="(type, itemKey) in fileTypes" :key="type"><span v-if="itemKey > 0">/</span>{{ type }}</span> no larger than {{ maxSize / 1000 }}kb</span></label>
      <div class="file-upload">
        <input 
          style="display: none"
          type="file"
          @change="onFileChanged"
          ref="fileInput"
        >
        <div @click="$refs.fileInput.click()" class="file-upload--name"><span v-if="fileName.length">{{ fileName }}</span></div>
        <ButtonAutodesk class="btn btn--autodesk-outline" label="Upload" @clicked="uploadHandler"/>

        <div v-if="loading" class='progress'>
          <div class='progress-seek' :style="{ width: percent+'%' }"></div>
        </div>
      </div>
    </div>
    <div v-if="images && images.length">
      <div v-for="(image, count) in images" :key="count" class="image-container">
        <div v-if="image.directus_files_id" class='image-info'>
          <Confirmation 
            title="Are you sure you want to delete this image?" 
            @confirm="confirmDelete(image)" 
            @cancel="cancelDelete" 
            v-if="showConfirmation === image.directus_files_id.id"
          />
          <img class="image-icon" src='../../assets/images/image-icon.svg' alt=''>
          <span>{{ image.directus_files_id.filename_download }}</span>
          <span class="file-size">{{ Math.round(image.directus_files_id.filesize / 1000) }}kb</span>
          <a class="delete-icon" @click="deleteFile(image)"><img src='../../assets/images/cross.svg' alt=''></a>
        </div>
        <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=full-700`" :alt='image.directus_files_id.filename_download'>
      </div>
    </div>
  </div>
</template>

<script>
  import { axiosInstance, baseUrl } from '../../services/api'
  import Confirmation from '../Confirmation'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  export default {
    name: 'FileUpload',
    components: {
      Confirmation,
      ButtonAutodesk
    },
    props: {
      images: Array
    },
    data() {
      return {
        showConfirmation: '',
        baseUrl: '',
        selectedFile: null,
        percent: 0,
        loading: false,
        fileTypes: ['png', 'jpg'],
        maxSize: 300000,
        fileName: '',
        fileSize: 0,
        fileType: ''
      }
    },
    methods: {
      deleteFile (file) {
        this.showConfirmation = file.directus_files_id.id
      },
      confirmDelete (file) {
        this.showConfirmation = ''
        this.$emit("deleteFile", file.directus_files_id.id)
      },
      cancelDelete () {
        this.showConfirmation = ''
      },
      calculatePercent (value, total) {
        return Math.round(value / total * 100)
      },
      onFileChanged (event) {
        if (event.target.files[0]) {
          this.selectedFile = event.target.files[0]
          this.fileName = event.target.files[0].name
          this.fileType = event.target.files[0].type
          this.fileSize = event.target.files[0].size
        }
      },
      resetFileUpload () {
        this.fileName = ''
        this.fileType = ''
        this.fileSize = 0
      },
      async uploadHandler () {
        // verify 
        let type = this.fileType.split('/')[1]
        type = type === 'jpeg' ? 'jpg' : type
        if (!this.fileTypes.includes(type)) {
          this.$notify({  
            title: "Wrong file type",
            group: 'custom-notification'
          })
          this.resetFileUpload()
          return
        }
        if (this.maxSize < this.fileSize) {
          this.$notify({ 
            title: `Files must be below ${this.maxSize / 1000}kb`,
            group: 'custom-notification'
            })
          this.resetFileUpload()
          return
        }
        try {
          this.loading = true
          const formData = new FormData()
          // let accessToken = getCookie('tdv_access_token')
          formData.append('title', this.fileName)
          formData.append('form', this.selectedFile)
        
          const response = await axiosInstance.post('files', formData, {
            onUploadProgress: (progress) => {
              this.percent = this.calculatePercent(progress.loaded, progress.total)
            }
          })
          this.loading = false
          this.$notify({ 
            title: "File uploaded",
            group: 'custom-notification'
          })
          this.$emit('file-uploaded', response.data.data)
          
          this.selectedFile = null
          this.fileName = '',
          this.fileSize = 0,
          this.fileType = ''

        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "File failed to upload",
            group: 'custom-notification'
          })
        }
      }
    },
    created () {
      this.baseUrl = baseUrl
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.file-field{
  margin-bottom:(24/$base-font)*1rem!important;
  .file-upload {
    display: flex;
    label {
      width: 100%;
    }
    .file-upload--name {
      cursor: pointer;
      background-color: $color-1;
      color: $color-22;
      flex: 3;
      box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.3), inset 0px -1px 0px #000000;
      margin-right: .5rem;
      display: flex;
      align-items: center;
      padding: .7rem;
    }
  }
  .progress, .progress-seek {
    height: 5px;
  }
  .progress {
    background-color: #eeeeee;
    padding:.25rem;
    margin: 1rem;
  }

  .progress-seek {
    background-color: green;
  }

}
.image-container {
  position: relative;
  margin-bottom: 1rem;
  .image-info {
    display: flex;
    font-size: (14/$base-font*1rem);
    align-items: center;
    .image-icon {
      margin-right: .5rem;
    }
    .delete-icon {
      padding: .5rem;
      cursor: pointer;
    }
    .file-size {
      color: $color-22;
      margin-left: .5rem;
    }
  }
}

  
</style>
