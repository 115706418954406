<template>
  <div class='page-wrapper'>
    <Header />
      <main class="container">
        <div class="row">
          <div class="col">
            <h2>{{ id }} - Sorry, something went wrong</h2>
          </div>
        </div>
      </main>
    <Footer />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Header from '../components/Header'
  import Footer from '../components/Footer'

  export default {
    name: 'ErrorPage',
    components: {
      Header,
      Footer
    },
    props: ['id'],
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  
</style>