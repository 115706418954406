<template>
  <div class="suggested-wrapper">
    <div v-if="mobileView" class='header-wrapper' :class="{ isOpen }" @click="isOpen = !isOpen">
      <h5>Research tips</h5>
      <ToolTip />
    </div>
    <div v-else class='header-wrapper'>
      <h5>Research tips</h5>
      <ToolTip class="me-auto"/>
    </div>
    <div v-if="isOpen || !mobileView" class='suggested-details'>
      <p>Use these search engine keywords to research your client</p>
      <ul>
        <li @click="copyLink">{{ companyName }} & sustainability</li>
        <li @click="copyLink">{{ companyName }} & innovation</li>
        <li @click="copyLink">{{ companyName }} & ESG</li>
        <li @click="copyLink">{{ companyName }} & UN sustainable Development Goals</li>
        <li @click="copyLink">{{ companyName }} & Sustainability report</li>
      </ul>
    </div>
  </div>
</template>

<script>
  import ToolTip from './ToolTip'
  export default {
    name: 'ReportSuggested',
    props: {
        stage: String
    },
    components: {
      ToolTip
    },
    data () {
      return {
        isOpen: false
      };
    },
    methods:{
      copyLink (e) {

        var tip = e.currentTarget.innerHTML
        const copytemp = document.createElement('input')
        document.body.appendChild(copytemp)
        copytemp.setAttribute('id', 'copytemp_id')
        copytemp.style.opacity = 0
        copytemp.style.height = 0

        document.getElementById('copytemp_id').value = tip
        copytemp.select()
        document.execCommand('copy')
        document.body.removeChild(copytemp)
        this.$notify({  
          title: "Copied to clipboard",
          group: 'custom-notification',
          text: tip,
          duration: 10000
          })
      },
    },
    computed: {
      companyName: function () {
        return this.$store.getters.companyName
      },
      mobileView: function () {
        return this.$store.getters.mobileView
      },
    }
  }
</script>

<style scoped lang="scss">
  .suggested-wrapper {
    margin-top: (52/$base-font)*1rem;
    margin-bottom: (40/$base-font)*1rem;;
    background: $color-1;
    box-shadow: inset 0px -1px 0px #666666, inset 0px 0px 0px 1px rgba(204, 204, 204, 0.5);
    border-radius: 8px;
    padding: (24/$base-font)*1rem;
    .header-wrapper {
      position: relative;
      display: flex;
      padding-top: (4/$base-font)*1rem;
      h5 {
        margin-bottom: (2/$base-font)*1rem;
      }
      .tooltip-inner {
        right: -10px;
      }
    }
    ul {
      list-style: none;
      padding-left: 2em;
      li {
        margin-bottom: (4/$base-font)*1rem;
        line-height: 1.3rem;
        cursor: copy;
        &:before {
          content: "-";
          text-indent: -2em;
          display: inline-block;

        }
      }
    }
    &.mobile {
      padding: (16/$base-font)*1rem;
      .header-wrapper { 
        cursor: pointer;
        padding-left: 30px;
        &::before {
          position: absolute;
          right: 0;
          top: 7px;
          width: 13px;
          height: 13px;
          border: none;
          content: "";
          background-image: url('../assets/images/caret-down-dark.svg');
          background-repeat: no-repeat;
        }
        &.isOpen:before {
          transform: rotate(180deg);
 
        }
        .tooltip-inner {
          right: unset;
          left: -10px;
        }
      }
      .suggested-details {
        margin-top: (16/$base-font)*1rem;
      }
    }
  }

</style>