<template>
  <div class='select-wrapper'>
    <ToolTip v-if="toolTip" :msg="toolTip" class="top-neg-5"/>
    <label for="{{ name }}">{{ label }}</label>
    <v-select v-if="isGoals"
      label="goalLabel"
      :clearable="isClearable"
      :deselectFromDropdown="true"
      v-model="inputVal"
      :options="setOptions"
      :multiple="multiple"
      :placeholder="setPlaceholder"
      @search="fetchOptions"
    ></v-select>
    <v-select v-else
      :clearable="isClearable"
      :deselectFromDropdown="true"
      v-model="inputVal"
      :options="setOptions"
      :multiple="multiple"
      :placeholder="setPlaceholder"
      @search="fetchOptions"
    ></v-select>
  </div>
</template>
<script>
import ToolTip from '../ToolTip'
export default {
  name: 'Select',
  components: {
    ToolTip
  },
  props: {
    label: String,
    placeholder: {
      type: String,
      default: ""
    },
    name: String,
    modelValue: [String, Object],
    options: {
      type: [Array, Object]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    firstIsPlaceholder: {
      type: Boolean,
      default: false
    },
    stripFirstWord: {
      type: Boolean,
      default: false
    },
    toolTip: String,
    isClearable: {
      type: Boolean,
      default: true
    },
    isGoals: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    fetchOptions (search) {
      this.$emit('search', search)
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('update:modelValue', val)
      }
    },
    setOptions: function () {
      let optionsFiltered = this.options
      if (this.firstIsPlaceholder && optionsFiltered.length) {
        optionsFiltered = this.options.slice(1)
      }
      /* if (this.isGoals) {
        optionsFiltered = optionsFiltered.map((option) => {
          option.goalLabel = `${option.number} ${option.label}`
          return option.goalLabel
        })
      } */
      return optionsFiltered
    },
    setPlaceholder: function () {
      let placeholder = ''
      if (this.placeholder) {
        placeholder = this.placeholder
      } else if (this.firstIsPlaceholder) {
        if (this.options[0] && this.options[0].label) {
          if (this.stripFirstWord) {
            // deal with non label numbered option
            placeholder = this.options[0].label.split(/ (.*)/)[1]
          } else {
            placeholder = this.options[0].label
          }
        }
      }
      return placeholder
    },
    setValue: function () {
      let val = []
      if (this.modelValue && this.modelValue.length && this.modelValue.isArray) {
        this.modelValue.forEach(v => {
          if (v.label) {
            let split = v.label.split("", 4)
            val = val.concat(' ', split)
          }
        })
      }
      return val
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.select-wrapper {
  position: relative;
  .v-select {
    &.hideClear {
      .vs__actions {
        button.vs__clear {
          display: none;
        }
      }
    }
    .vs__search .vs__selected  {
      color: $color-2;
    }
    .vs__actions {
      display: flex;
      align-items: center;
      padding: 4px 6px 0 3px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 2px;
    }

    .vs__dropdown-option {
      white-space: pre-wrap;
    }
    .vs__dropdown-toggle {
      height: 44px;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 10px;
      .vs__selected-options {
        .vs__search {
          padding-left: 10px;
          text-overflow: ellipsis;
          &::placeholder {
            color: $color-22;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
          }
        }
        position: absolute;
        height: 44px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        overflow: hidden;

        .vs__selected {
          max-width: 100%;
          display: block;
          position: absolute;
          max-width: 97%;
          overflow: hidden;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          padding-right: 29px;
          
        }
      }
    }
    .vs__dropdown-option {
      position: relative;
      &.vs__dropdown-option--selected {
        &:before {
          position: absolute;
          right: 5px;
          top: 5px;
          width: 20px;
          height: 20px;
          border: none;
          content: "";
          background-image: url('../../assets/images/tick.svg');
          background-repeat: no-repeat;
        }
      }
    }
    &.vs--multiple {
      .vs__selected-options {
        
        .vs__selected {
          background: $color-1;
          border: 0px;
          .vs__deselect {
            display: none;
          }
        }
      }
      .vs__dropdown-option {
        position: relative;
        padding-left: 40px;

        &:before {
          position: absolute;
          left: 5px;
          top: 5px;
          width: 20px;
          height: 20px;
          border: none;
          content: "";
          background-image: url('../../assets/images/checkbox-empty.svg');
          background-repeat: no-repeat;
        }
        &.vs__dropdown-option--selected {
          &:before {
            background-image: url('../../assets/images/checkbox-checked.svg');
          }
        }
      }
    }
  }
}

</style>
