<template>
  <div class='page-wrapper'>
    <div id='report-output'>
        <div class='cover'>
          <img class="logo" src="../assets/images/logo-dark.svg" alt="">
          <h1>{{ companyName }}</h1>
          <h2>{{ shortDescription }}</h2>
          <h5>Region(s)</h5>
          <p v-if="reportRegion && reportRegion.label">{{ reportRegion.label }}</p>
          <h5>Industry sector</h5>
          <p v-if="reportIndustry && reportIndustry.label">{{ reportIndustry.label }}</p>
          <h5>Last modified</h5>
          <p v-if="lastModified">{{ lastModified }}</p>
          <div id='cover-image-container'>
            <img v-if="coverImage" crossorigin="anonymous" class="cover-image" :data-src="`${baseUrl}assets/${coverImage}.png?key=full-700`" :src="`${baseUrl}assets/${coverImage}.png?key=full-700`" alt='Earth from space'>
          </div>
          <div class='break'></div>
        </div>
        <div id='company' class='report-section'>
          <h3>The company</h3>
          <p><span>Customer Serial Number</span>{{ customerSerialNumber }}</p>
          <p><span>Company website</span><a :href='companyWebsite' target='blank'>{{ companyWebsite }}</a></p>
          <p><span>Company location</span>{{ companyLocation }}</p>
          <p><span>Company size</span><span v-if="reportCompanySize && reportCompanySize.label">{{ reportCompanySize.label }}</span></p>
          <p><span>Company LinkedIn profile</span><a :href='companyLinkedinProfile' target='blank'>{{ companyLinkedinProfile }}</a></p>
        </div>
        <div class='break'></div>
        <div v-if="reportSustainabilityGoals && reportSustainabilityGoals.length && reportSustainabilityGoals[0].sustainability_goal && reportSustainabilityGoals[0].sustainability_goal.label" 
          id='sustainability-goals' 
          class='report-section'>
          
          <h3>Sustainability goals</h3>
          <div v-for="goal in reportSustainabilityGoals" class='goal item' :key='goal.id'>
            <h4 v-if="goal.sustainability_goal && goal.sustainability_goal.label">{{ goal.sustainability_goal.label }}</h4>
            <p v-if="goal.url">{{ goal.url }}</p>
            <div v-if="goal.images && goal.images.length">
              <div v-for="(image, count) in goal.images" :key="count" class="image-container">
                <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=full-700`" :alt='image.directus_files_id.filename_download'>
              </div>
            </div>
            <div v-if="goal.additional_information" class='additional-info'>{{ goal.additional_information }}</div>
          </div>
        </div>
        <div class='break'></div>
        <div v-if="reportContacts && reportContacts.length && reportContacts[0].name" id='contacts' class='report-section'>
          <h3>Contacts</h3>
          <div v-for="contact in reportContacts" class='contact' :key='contact.id'>

            <div v-if="contact.images && contact.images.length" class='contact-image'>
              <div v-for="(image, count) in contact.images" :key="count" class="image-container">
                <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=small-200`" :alt='image.directus_files_id.filename_download'>
              </div>
            </div>

            <div class='contact-details'>
              <h4 v-if="contact.name">{{ contact.name }}</h4>
              <p v-if="contact.job_title">{{ contact.job_title }}</p>
              <p v-if="contact.telephone_number">{{ contact.telephone_number }}</p>
              <p v-if="contact.email"><a :href='`mailto:${contact.email}`' target='blank'>{{ contact.email }}</a></p>
              <p v-if="contact.linkedin_profile"><a :href='contact.linkedin_profile' target='blank'>{{ contact.linkedin_profile }}</a></p>
              <p v-if="contact.last_contacted">Last contacted:{{ dateTimeFormat(contact.last_contacted) }}</p>
              <div v-if="contact.additional_information" class='additional-info'>{{ contact.additional_information }}</div>
            </div>
          </div>
        </div>
        <div class='break'></div>
        <div v-if="reportFinancialRecords && reportFinancialRecords.length && reportFinancialRecords[0].title" id='financial-data' class='report-section'>
          <h3>Financial data</h3>
          <div v-for="record in reportFinancialRecords" class='record item' :key='record.id'>
            <h4 v-if="record.title">{{ record.title }}</h4>
            <p v-if="record.url"><a :href='record.url' target='blank'>{{ record.url }}</a></p>
            <div v-if="record.images && record.images.length">
              <div v-for="(image, count) in record.images" :key="count" class="image-container">
                <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=full-700`" :alt='image.directus_files_id.filename_download'>
              </div>
            </div>
            <div v-if="record.additional_information" class='additional-info'>{{ record.additional_information }}</div>
          </div>
        </div>
        <div class='break'></div>
        <div v-if="reportSupportingSources && reportSupportingSources.length && reportSupportingSources[0].title" id='supporting-sources' class='report-section'>
          <h3>Supporting sources</h3>
          <div v-for="source in reportSupportingSources" class='record item' :key='source.id'>
            <h4>{{ source.title }}</h4>
            <p v-if="source.publication_date">{{ dateTimeFormat(source.publication_date) }}</p>
            <p v-if="source.url"><a href='{{ source.url }}' target='blank'>{{ source.url }}</a></p>
            <div v-if="source.images && source.images.length">
              <div v-for="(image, count) in source.images" :key="count" class="image-container">
                <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=full-700`" :alt='image.directus_files_id.filename_download'>
              </div>
            </div>
            <div v-if="source.additional_information" class='additional-info'>{{ source.additional_information }}</div>
          </div>
        </div>
        <div class='break'></div>
        <div v-if="reportAccreditations && reportAccreditations.length && reportAccreditations[0].title" id='accreditations' class='report-section'>
          <h3>Accreditations</h3>
          <div v-for="item in reportAccreditations" class='record item' :key='item.id'>
            <h4 v-if="item.title">{{ item.title }}</h4>
            <p v-if="item.publication_date">{{ item.publication_date }}</p>
            <p v-if="item.url"><a :href='item.url' target='blank'>{{ item.url }}</a></p>
            <div v-if="item.images && item.images.length">
              <div v-for="(image, count) in item.images" :key="count" class="image-container">
                <img v-if="image.directus_files_id" class="img-fluid" :src="`${baseUrl}assets/${image.directus_files_id.id}?key=full-700`" :alt='image.directus_files_id.filename_download'>
              </div>
            </div>
            <div v-if="item.additional_information" class='additional-info'>{{ item.additional_information }}</div>
          </div>
        </div>
        <div id='resource-links'>
          <h3>Resource links</h3>
          <div class='link d-flex'>
            <h4>Autodesk Impact Report</h4>
            <ButtonAutodesk class="btn btn--autodesk-outline" label="Read" href="https://damassets.autodesk.net/content/dam/autodesk/www/sustainability/docs/pdf/autodesk-fy2022-impact-report.pdf" target="_blank"/>
          </div>
          <div class='link d-flex'>
            <h4>Autodesk Sustainable Technology webpage</h4>
            <ButtonAutodesk class="btn btn--autodesk-outline" label="Read" href="https://www.autodesk.com/sustainability/sustainable-design-technology" target="_blank"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { format } from 'date-fns'
  import { fetchFullReport, axiosRefresh, baseUrl } from '../services/api'
  import ButtonAutodesk from '../components/buttons/ButtonAutodesk'

  export default {
    name: 'ReportOutput',
    props: ['id', 'token'],
    components: {
      ButtonAutodesk
    },
    data() {
      return {
        report: {},
        coverImage: {},
        baseUrl: ''
      }
    },
    methods: {
      ...mapActions(['setReport']),
      async fetchReport () {
        try {
          fetchFullReport(this.id, this.token).then((report) => {
            this.setReport(report)
          })
        } catch (error) {
          console.log('get report error', error)
        }
      },
      async fetchCoverImage () {
        try {
          const image = await axiosRefresh.get(`/items/site_options?access_token=${this.token}`)
          this.coverImage = image.data.data.pdf_cover_image
        } catch (error) {
          console.log('Cover image error', error)
        }
      },
      dateTimeFormat (dateTime) {
        if (dateTime && dateTime.length) {
          dateTime  = new Date(dateTime)
          dateTime = format(dateTime, 'do MMM yyyy')
        }
        return dateTime
      },
      // optionally replace image with base64 to get around cors issues - call this in image scr in template
      getBase64Image (imageURL) {
        let downloadedImg = new Image;
        downloadedImg.crossOrigin = "Anonymous";
        downloadedImg.addEventListener("load", imageReceived, false)
        downloadedImg.src = imageURL
        function imageReceived () {
          let canvas = document.createElement("canvas")
          let context = canvas.getContext("2d")

          canvas.width = downloadedImg.width;
          canvas.height = downloadedImg.height
          context.drawImage(downloadedImg, 0, 0)
          var dataURL = canvas.toDataURL('image/png')
          const image = document.querySelector(`[data-src='${imageURL}']`)
          image.src = imageURL
          return dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
        }
      }
    },
    created () {
      this.baseUrl = baseUrl
      this.fetchCoverImage()
      this.fetchReport()
    },
    mounted () {
      // this.getPDF()
    },

    computed: {
      ...mapGetters([
        'companyName',
        'shortDescription',
        'reportRegion',
        'reportIndustry',
        'reportCompanySize',
        'customerSerialNumber',
        'companyLocation',
        'companyWebsite',
        'companyLinkedinProfile',
        'reportSustainabilityGoals',
        'reportContacts',
        'reportFinancialRecords',
        'reportSupportingSources',
        'reportAccreditations',
        'tealium'
      ]),
      lastModified: function () {
        let dateTime
        if (this.$store.getters.lastModified && this.$store.getters.lastModified.length) {
          dateTime  = new Date(this.$store.getters.lastModified)
          dateTime = format(dateTime, 'do MMM yyyy, hh:mm a')
        }

        return dateTime
      },
    }
  }
</script>

<style lang="scss">


  #report-output {
    $base-font-pdf: 24;

    img, #resource-links, #cover-image-container .avoid-break {
      break-inside: avoid!important;
    }
    .page-wrapper {
      position: relative;
      background: $color-12;
    }

    background: $color-1;
    width: 210mm;
    margin: auto;
    padding: 0 (180/$base-font-pdf)*1rem;
    position: relative;
    h1 {
      font-size: (54/$base-font-pdf)*1rem;
      margin-top: 0;
      line-height: (64/$base-font-pdf)*1rem;
      margin-bottom: (40/$base-font-pdf)*1rem;
    }
    h2 {
      font-family: ArtifaktLegend-Regular;
      font-weight: normal;
      font-size: (28/$base-font-pdf)*1rem;
      margin-bottom: (54/$base-font-pdf)*1rem;
    }
    h3 {
      font-family: ArtifaktLegend-ExtraBold;
      font-size: (34/$base-font-pdf)*1rem;
      margin-bottom: (68/$base-font-pdf)*1rem;
    }
    h4 {
      font-family: ArtifaktLegend-ExtraBold;
      font-size: (21/$base-font-pdf)*1rem;
      margin-bottom: (16/$base-font-pdf)*1rem;
    }
    h5 {
      font-family: ArtifaktElement-Bold;
      font-size: (21/$base-font-pdf)*1rem;
      margin-bottom: (8/$base-font-pdf)*1rem;
    }
    a {
      text-decoration: underline;
    }
    p, a {
      font-size: (18/$base-font-pdf)*1rem;
      margin-bottom: (24/$base-font-pdf)*1rem;
      color: $brand-color-1;
    }
    .break { 
      break-after: always;
    }
    .image-container {
      margin-bottom: (64/$base-font-pdf)*1rem;
    }
    .report-section {
      padding-bottom: (72/$base-font-pdf)*1rem;
      margin-bottom: (72/$base-font-pdf)*1rem;
      border-bottom: 1px solid #808080;
    }
    .additional-info {
      white-space: pre-wrap;
      font-family: ArtifaktLegend-Regular;
      font-size: (18/$base-font-pdf)*1rem;
    }
    .cover {
      padding-bottom: 600px;
      .logo {
        width: (322/$base-font-pdf)*1rem;
        margin-bottom: (66/$base-font-pdf)*1rem;
        margin-left: -10px;
      }
      .cover-image {
        position: absolute;
        top: 400px;
        left: -20px;
      }
    }


    #company {
      page-break-inside: avoid;
      p {
        span {
          &:first-child {
            width: (200/$base-font-pdf)*1rem;
            display: inline-block;
            font-size: (12/$base-font-pdf)*1rem;
          }
        }
      }
    }

    #contacts {
      margin-bottom: (72/$base-font-pdf)*1rem;
      .contact {
        margin-bottom: (72/$base-font-pdf)*1rem;
        display: flex;
        .contact-image {
          width: (168/$base-font-pdf)*1rem; 
          margin-right: (64/$base-font-pdf)*1rem;
          margin-bottom: (32/$base-font-pdf)*1rem;
          img {
            width: 100%;
          }  
        }
        .contact-details {
          flex: 1;
          p {
            margin-bottom: (8/$base-font-pdf)*1rem;
          }
        }
      }
    }
    #resource-links {
      .link {
        margin-bottom: (49/$base-font-pdf)*1rem;;
        align-items: center;
        h4 {
          margin: 0;
        }
        a.btn.btn--autodesk-outline{
          text-decoration: none;
          margin-left: (16/$base-font-pdf)*1rem;
          margin-bottom: 0;
        }
      }
    }

    .item {
      border-bottom: 1px solid #808080;
      padding-bottom: (72/$base-font-pdf)*1rem;
      margin-bottom: (72/$base-font-pdf)*1rem;
      &:last-child {
        border-bottom: 0px;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

  }

</style>