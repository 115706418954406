<template>
  <div class='col' :class="inputClass">
    <div class="block__card__v2">
      <a :href="'/reports/' + report.id + '/report-overview'">
        <div class='block__card-content'>
          <div class='block__card-lower'>
            <div class='card-text'>
              <h3 class='title' v-if="tag ==='h3'">{{ stringLimit(report.company_name, 36) }}</h3>
              <h4 v-else>{{ stringLimit(report.company_name, 36) }}</h4>
              <div class='block__body-text'>
                <p class="short-description" v-if="report.short_description">{{ stringLimit(report.short_description, 92) }}</p>
              </div>
              <div class='points'>
                <p class='region'>
                  <strong>Region(s): </strong>
                  <span v-if="report.region"> {{ report.region.label }}</span>
                </p>
                <p>
                  <strong>Last edited by: </strong>
                  <span v-if="report.user_updated && report.user_updated.first_name">{{ report.user_updated.first_name }}&nbsp;</span> 
                    <span v-if="report.user_updated && report.user_updated.last_name">{{ report.user_updated.last_name }}</span>
                  <span v-else>
                    <span v-if="report.user_created && report.user_created.first_name">{{ report.user_created.first_name }}&nbsp;</span> 
                    <span v-if="report.user_created && report.user_created.last_name">{{ report.user_created.last_name }}</span>
                  </span>
                </p>
                <p> 
                  <strong>Last modified: </strong>
                  <span v-if="date">{{ date }}</span>
                </p>
              </div>
            </div>
            <div class='block__link'>
              <span class='internal-text-link-icon'>View report</span>
              <span v-if="report.industry_sector" class="shortCodeFill"><span class='shortCode'>{{ report.industry_sector.short_code }}</span></span>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'reportCard',
  components: {},
  props: {
    report: Object,
    tag: String,
    col: String
  },
  data() {
    return {
      date: '',
    }
  },
  methods: {
    viewReport(id) {
      this.$router.push(`/reports/${id}/report-overview`)
    },
    dateTime() {
      let dateTime = this.report.date_updated
      if (dateTime === null){
        dateTime = this.report.date_created
      }
      dateTime = new Date(dateTime)
      if (dateTime && dateTime instanceof Date) {
        dateTime = format(dateTime, 'do MMM yyyy, hh:mm a')
        this.date = dateTime
      }
    },
    stringLimit(value, size) {
      if (!value) return '';
      value = value.toString();
      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '...';
    }
  },
  created() {
    this.dateTime()
  },
  computed: {
    inputClass() {
      let bootstrapCol = ''
      if (this.$route.path && (this.$route.path === '/my-reports' || this.$route.path.includes('search'))) { 
        bootstrapCol = 'col-md-6'
      }
      if (this.col && this.col.length) {
        bootstrapCol = `col-md-6 col-xl-${this.col}`
      }
      return bootstrapCol
    }
  }
}
</script>

<style scoped lang="scss">
.col {
  margin-bottom: (24/$base-font)*1rem;
}
.block__card__v2 {
  box-shadow: $shadow-1;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  background: $color-1;
  a {
    text-decoration: none;
    &:hover {
      .block__card-lower {
        background: $gradient-1;
      }
    }
    &:active {
      .block__card-lower {
        background: $gradient-2;
      }
    }
    .block__card-content {
      height: 100%;
      h3 {
        margin-top: 0;
        margin-bottom: .25rem;
        line-height: 1.3;
          @media screen and (max-width: 1200px) { 
            line-height: 1.34;
          }
      }
      h4 {
        margin-top: 0;
        margin-bottom: (8/$base-font)*1rem;
        line-height: 2rem;
      }
      .block__card-lower {
        padding-top: 1.5rem;
        height: 100%;
        display:flex;
        flex-direction: column;
        .card-text {
          padding: 0 1.5rem;
          margin-bottom: 1.5rem;
          flex: 1;
          display:flex;
          flex-direction: column;
          p {
            margin : 0 0 (12/$base-font)*1rem;
          }
        }
        .block__body-text {
          margin: 0; 
          p {
            margin-bottom: (8/$base-font)*1rem;
          }
        }
        .points {
          margin-top: auto;
          p {
            font-size: (12/$base-font)*1rem;
            margin-bottom: 0;
            &.region {
              margin-bottom: (32/$base-font)*1rem;
            }
          }
        }
      }
      .block__link {
        padding: 0 1rem;
        background: $color-13;
        box-shadow: $shadow-2;
        margin-top: auto;
        height: 70px;
        display: flex;
        align-items: center;
        color: $color-2;
        text-decoration: none;
        font-size: (14/$base-font)*1rem;
        .external-text-link-icon {
          padding: 0;
          margin: 1.5rem 0 0;
        }
        .internal-text-link-icon {
          padding: 0 0 0 (24/$base-font)*1rem;
          display: inline-block;
          position: relative;
          &::after {
            content: url('../assets/images/cta-arrow-right.svg')!important;
            position: absolute;
            left: 0;
            top: (1/$base-font)*1rem!important;
          }
        }
        .shortCodeFill {
          margin-left: auto;
          background: $color-2;
          color: $color-1;
          border-radius: 1000px;
          font-size: (14/$base-font)*1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: (4/$base-font)*1rem (8/$base-font)*1rem;
        }

      }
    }
  }
  &.block__card__v2--no-content {
    .block__card-lower { 
      padding-top: 0;
    }
  }
}
</style>
