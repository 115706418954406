<template>
  <ul class='social-icons'>
    <li><a href='https://www.facebook.com/autodeskUKI/'><img src='../assets/images/facebook.svg' alt='facebook-icon'></a></li>
    <li><a href='https://www.linkedin.com/company/autodesk/'><img src='../assets/images/linkedin.svg' alt='linkedin-icon'></a></li>
    <li><a href='https://twitter.com/autodesk'><img src='../assets/images/twitter.svg' alt='twitter-icon'></a></li>
    <li><a href='https://twitter.com/autodesk'><img src='../assets/images/youtube.svg' alt='twitter-icon'></a></li>
  </ul>
</template>

<script>
  export default {
    name: 'SocialIcons',
    methods: {
      
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .social-icons {
    display: flex;
    li {
      list-style: none;
      margin-right: .5rem;
    }
  }

</style>
