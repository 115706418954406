<template>
  <div 
    v-if="msg"
    class="tooltip-inner"
    @mouseover="hover = true"
    @mouseleave="hover = false">
    <div class="tooltip-icon">
      <img src="../assets/images/information-icon.svg" alt="tooltip info">
    </div>
    <div v-if="hover" class="message">
      <div class="message-bubble">
        {{ msg }}
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'ToolTip',
    props: {
      msg: String,
    },
    data() {
      return {
        hover: false,
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .tooltip-inner {
    margin-left:10px;
    position: relative;
    background: inherit;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0rem;
    cursor: pointer;
    &.top-neg-5 {
      top: -5px;
    }
    &.top-neg-10 {
      top: -10px;
    }
  }

  .colour-square{
    width: 8px;
    height: 8px;
    background: #CCCCCC;
  }
  .message {
    position: absolute;
    right: 0px;
    bottom:10px;
    width: 200px;
    padding-left:10px;
    padding-bottom: 19px;
    z-index: 10;
    color: $brand-color-1;
    font-size: (12/$base-font)*1rem;
  }
  .message-bubble {
    width:100%;
    box-shadow: 0px 2px 2px #00000029;
    background: #FFFFFF;
    padding:.5em .8em;
    position: relative;
  }
  .message-bubble::after {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 19px;
    margin-left: -5px;
    box-shadow: 0px 2px 2px #00000029;;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    z-index: -1;
  }
  .message-bubble::before {
    content: " ";
    position: absolute;
    bottom: -12px;
    right: 16px;
    border-width: 7px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
  }
  .tooltip-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .tooltip-icon img {
    width: 24px;
    height: 24px;
  }

</style>
