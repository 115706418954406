<template>
    <a class="button-link" v-if="href && href.length" :href="href" :target="target">{{ label }}</a>
    <button v-else class="btn" @click="$emit('clicked')" type="button">
      {{ label }}
      <slot></slot>
    </button>
</template>
<script>
export default {
  name: 'ButtonAutodesk',
  props: {
    label: String,
    href: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
a.button-link {
  display: block;
}
.btn {
  position: relative;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  padding: (9/$base-font) * 1rem (20/$base-font) * 1rem;
  text-decoration: none;

  &.btn--autodesk-wide {
    width: 100%;
  }
  &.btn--autodesk-dark {
    background: $color-2;
    color: $color-1;
  }
  &.btn--autodesk-outline {
    border: 1px solid $color-2;
    background: $color-13;
  }
  &.btn--autodesk-dark-outline {
    border: 1px solid $color-1;
    background: $color-13;
    color: $color-1;
  }
  &.btn--autodesk-dark-solid {
    background: $color-1;
    color: $color-2;
  }
  &.btn--autodesk-text {
    border: 0;
    background: inherit;
  }
  &.btn--autodesk-cancel {
    border: 0;
    background: inherit;
    padding-right: 2.3rem;
     &:before {
      content: '';
      background: url('../../assets/images/cross.svg');
      background-repeat: no-repeat;
      width: 13px;
      height: 13px;
      position: absolute;
      top: 15px;
      right: 8px;
    }
  }
  &.btn--autodesk--icon {
    padding: (9/$base-font)*1rem (10/$base-font)*1rem;
    display: flex;
  }
  &.btn-small {
    padding: (6/$base-font) * 1rem (12/$base-font) * 1rem;
    font-size: (14/$base-font) * 1rem;
  }
  &.btn--autodesk-step-back {
    top: -4.5rem;
    padding-left: 2rem;
    position: absolute;
    border: 0;
    left: -5px;
    background: inherit;
    &:before {
      content: '';
      background: url('../../assets/images/caret-right.svg');
      background-repeat: no-repeat;
      transform: rotate(180deg);
      width: 10px;
      height: 15px;
      position: absolute;
      top: 14px;
      left: 5px;
    }
  }

  &.btn--autodesk-step-next {
    border: 0;
    padding-right: 2rem;
    background: inherit;
    position: relative;
    right: -5px;
    &:before {
      content: '';
      background: url('../../assets/images/caret-right.svg');
      background-repeat: no-repeat;
      width: 10px;
      height: 15px;
      position: absolute;
      top: 13px;
      right: 5px;
    }
  }

  &.btn--autodesk-clear {
    border: 0;
    background: inherit;
    &:before {
      content: '';
      background: url('../../assets/images/cross.svg');
      background-repeat: no-repeat;
      width: 10px;
      height: 15px;
      position: absolute;
      top: 14px;
      left: 0;
    }
  }

  &:hover {
    box-shadow: 0px 0px 0px 1px #8080FF;
  }
  &:focus {
    box-shadow: 0px 0px 0px 1px #FFFFFF, 0px 0px 0px 3px #5F60FF;
  }
  &:active {
    box-shadow: 0px 0px 0px 2px #2BC275;
  }
}
</style>
