<template>

  <div class='row'>
    <div class='col'>
      <nav class="report-nav nav nav--primary navbar" v-if="stage !== 'create-report'">
          <div class="nav-row" v-if="mobileView">
            <ul class="navbar-nav">
              <li v-for="item in reportMenu" class="nav-item nav__item menu-item dropdown" :key="item.slug">
                  <div v-if="stage === item.slug">
                    <a @click="showDrop = !showDrop" role="button" class="nav-link nav__item__link dropdown-toggle" href="#">{{ item.title }}</a>
                      <DropDown @hide-drop="showDrop = false" v-if="showDrop" :content="reportMenu" :stage="stage" />
                  </div>
              </li>
            </ul>
            <ul class="navbar-nav ms-auto">
              <li><ButtonAutodesk class="btn btn-small me-2 btn--autodesk-dark-solid" label="Share url" @clicked="shareURL"/></li>
              <li><ButtonAutodesk class="btn btn-small btn--autodesk-dark-outline" label="Download pdf" @clicked="reportPDF"/></li>
            </ul>
          </div>
          <div class="nav-row" v-else>
            <ul class="navbar-nav">
              <li v-for="item in reportMenu" class="nav-item nav__item menu-item" :key="item.slug">
                <router-link class="nav-link nav__item__link" :to="`/reports/${reportID}/${item.slug}`"><span>{{ item.title }}</span></router-link>
              </li>
            </ul>
            <ul class="navbar-nav ms-auto">
              <li><ButtonAutodesk class="btn btn-small me-2 btn--autodesk-dark-solid" label="Share url" @clicked="shareURL"/></li>
              <li><ButtonAutodesk class="btn btn-small btn--autodesk-dark-outline" label="Download pdf" @clicked="reportPDF"/></li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
</template>

<script>
  import DropDown from '../DropDown'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import { refreshToken } from '../../services/api'

  export default {
    name: 'ReportNav',
    data() {
      return {
        showDrop: false,
        report: {},
        reportMenu: [
          { slug: 'report-overview', title: 'Report overview' },
          { slug: 'company', title: 'The Company' },
          { slug: 'contacts', title: 'Contacts' },
          { slug: 'financial-data', title: 'Financial Data' },
          { slug: 'supporting-sources', title: 'Supporting Sources' },
          { slug: 'accreditations', title: 'Accreditations' },
        ]
      };
    },
    props: {
      stage: String,
    },
    components: {
      DropDown,
      ButtonAutodesk
    },
    methods: {
      // get url 
      // copy to clipboard

      shareURL () {
        const url = `${window.location.protocol}//${window.location.host}/reports/${this.reportID}/report-overview`
        this.copyLink(url)
        this.$notify({  
          title: "Copied URL to clipboard",
          group: 'custom-notification',
          text: url,
          duration: 10000
          })
      },
      copyLink (url) {
        const copytemp = document.createElement('input')
        //$('#copytemp_id').css({ opacity: '0', height: '0' })
        // create temporary input to copy from
        document.body.appendChild(copytemp)
        copytemp.setAttribute('id', 'copytemp_id')
        copytemp.style.opacity = 0
        copytemp.style.height = 0

        document.getElementById('copytemp_id').value = url
        copytemp.select()
        document.execCommand('copy')
        document.body.removeChild(copytemp)
      },
      reportPDF () {
        // const stagingAccess = process.env.VUE_APP_ENV === 'development' ||  process.env.VUE_APP_ENV === 'staging' ? 'torpedo:20StrongAndSecure19!@' : ''
        const host = process.env.VUE_APP_ENV === 'development' ? 'adsk-sustainability.torpedo.agency' : window.location.host
        refreshToken().then((token) => {
          const url = `https://${host}/report-output/${this.reportID}/${token}`
          const pupeteerURL = process.env.VUE_APP_ENV === 'development' ? 'http://192.168.0.21:8090/api/v1/pdf' : `https://${host}/pdf/api/v1/pdf`
          // /api/v1/pdf?url=https://torpedo:20StrongAndSecure19!@adsk-sustainability.torpedo.agency/report-output/96
          // refresh token - add token to url
          // go to url
          //window.open(`${pupeteerURL}?url=${url}`)
          const a = document.createElement("a")
          a.setAttribute('href', `${pupeteerURL}?url=${url}`)
          a.setAttribute('target', '_blank')
          a.click()
        })

      }
    },
    computed: {
      reportID: function () {
        return this.$store.getters.reportID
      },
      mobileView: function () {
        return this.$store.getters.mobileView
      },
    },
    updated () {

    }
  }

  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.nav-row {
  display: flex;
  padding: 0;
  width: 100%;
}

.report-nav {
  border-top: 1px solid $color-3;
  .dropdown-toggle {
    padding-right: 1.5rem;
    padding-left: 0;
    margin-left: 0;
    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 19px;
      height: 6px;
      border: none;
      content: "";
      background-image: url('../../assets/images/caret-down.svg');
      background-repeat: no-repeat;
    }
  }
  .btn {
    margin: (4/$base-font)*1rem;
  }
}
.navbar-nav {
  height: 42px;
}
.dropdown-menu {
  top: 42px;
}

</style>
