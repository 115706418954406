
const state = {
  report: {},
  userCreated: {},
  lastModified: '',
  companyName: '',
  mobileView: false,
  shortDescription: '',
  customerSerialNumber: '',
  companyLocation: '',
  companyWebsite: '',
  companyLinkedinProfile: '',
  reportID: '',
  reportRegion: {},
  reportIndustry: {},
  reportCompanySize: {},
  regions: [],
  industrySectors: [],
  reportSustainabilityGoals: [],
  reportContacts: [],
  reportFinancialRecords: [],
  reportSupportingSources: [],
  reportAccreditations: [],
  reportOverviewNotes: [],
  activeGoal: 0,
  activeContact: 0,
  activeFinancialRecord: 0,
  activeSupportingSource: 0,
  activeAccreditation: 0,
  sustainabilityGoalOptions: [],
  overviewHasUpdates: false,
  companyHasUpdates: false,
  sustainabilityGoalsHasUpdates: false,
  contactsHasUpdates: false,
  financialDataHasUpdates: false,
  supportingSourcesHasUpdates: false,
  accreditationsHasUpdates: false,
  tealium: '//tags.tiqcdn.com/utag/autodesk/micro-opt-qa/qa/utag.js',
  notificationPosition: '',
  notificationWidth: '',
  reportLogs: []
}

const getters = {
  report: state => state.report,
  userCreated: state => state.userCreated,
  lastModified: state => state.lastModified,
  mobileView: state => state.mobileView,
  regions: state => state.regions,
  industrySectors: state => state.industrySectors,
  companySizes: state => state.companySizes,
  sustainabilityGoalOptions: state => state.sustainabilityGoalOptions,
  companyName: state => state.companyName,
  shortDescription: state => state.shortDescription,
  reportID: state => state.reportID,
  reportRegion: state => state.reportRegion,
  reportIndustry: state => state.reportIndustry,
  reportCompanySize: state => state.reportCompanySize,
  customerSerialNumber: state => state.customerSerialNumber,
  companyLocation: state => state.companyLocation,
  companyWebsite: state => state.companyWebsite,
  companyLinkedinProfile: state => state.companyLinkedinProfile,
  reportSustainabilityGoals: state => state.reportSustainabilityGoals,
  reportContacts: state => state.reportContacts,
  reportFinancialRecords: state => state.reportFinancialRecords,
  reportSupportingSources: state => state.reportSupportingSources,
  reportAccreditations: state => state.reportAccreditations,
  reportOverviewNotes: state => state.reportOverviewNotes,
  activeGoal: state => state.activeGoal,
  activeContact: state => state.activeContact,
  activeFinancialRecord: state => state.activeFinancialRecord,
  activeSupportingSource: state => state.activeSupportingSource,
  activeAccreditation: state => state.activeAccreditation,
  overviewHasUpdates: state => state.overviewHasUpdates,
  companyHasUpdates: state => state.companyHasUpdates,
  contactsHasUpdates: state => state.contactsHasUpdates,
  financialDataHasUpdates: state => state.financialDataHasUpdates,
  supportingSourcesHasUpdates: state => state.supportingSourcesHasUpdates,
  accreditationsHasUpdates: state => state.accreditationsHasUpdates,
  sustainabilityGoalsHasUpdates: state => state.sustainabilityGoalsHasUpdates,
  tealium: state => state.tealium,
  notificationPosition: state => state.notificationPosition,
  notificationWidth: state => state.notificationWidth,
  reportLogs: state => state.reportLogs
}

const actions = {
  setMobileView ({ commit }, input) {
    commit('setMobileView', input)
  },
  setActiveGoal ({ commit }, input) {
    commit('setActiveGoal', input)
  },
  setActiveContact ({ commit }, input) {
    commit('setActiveContact', input)
  },
  setActiveFinancialRecord ({ commit }, input) {
    commit('setActiveFinancialRecord', input)
  },
  setActiveSupportingSource ({ commit }, input) {
    commit('setActiveSupportingSource', input)
  },
  setActiveAccreditation ({ commit }, input) {
    commit('setActiveAccreditation', input)
  },
  setReport ({ commit }, input) {
    commit('setReport', input)
  },
  setCompanyName ({ commit }, input) {
    commit('setCompanyName', input)
  },
  setShortDescription ({ commit }, input) {
    commit('setShortDescription', input)
  },
  setCustomerSerialNumber ({ commit }, input) {
    commit('setCustomerSerialNumber', input)
  },
  setReportRegion ({ commit }, input) {
    commit('setReportRegion', input)
  },
  setReportIndustry ({ commit }, input) {
    commit('setReportIndustry', input)
  },
  setRegions ({ commit }, input) {
    commit('setRegions', input)
  },
  setIndustrySectors ({ commit }, input) {
    commit('setIndustrySectors', input)
  },
  setCompanySizes ({ commit }, input) {
    commit('setCompanySizes', input)
  },
  setSustainabilityGoalOptions ({ commit }, input) {
    commit('setSustainabilityGoalOptions', input)
  },
  setCompanyLocation ({ commit }, input) {
    commit('setCompanyLocation', input)
  },
  setCompanyWebsite ({ commit }, input) {
    commit('setCompanyWebsite', input)
  },
  setCompanyLinkedinProfile ({ commit }, input) {
    commit('setCompanyLinkedinProfile', input)
  },
  setReportSustainabilityGoals ({ commit }, input) {
    commit('setReportSustainabilityGoals', input)
  },
  setReportContacts ({ commit }, input) {
    commit('setReportContacts', input)
  },
  setReportFinancialRecords ({ commit }, input) {
    commit('setReportFinancialRecords', input)
  },
  setReportSupportingSources ({ commit }, input) {
    commit('setReportSupportingSources', input)
  },
  setReportAccreditations ({ commit }, input) {
    commit('setReportAccreditations', input)
  },
  setReportOverviewNotes ({ commit }, input) {
    commit('setReportOverviewNotes', input)
  },
  setCompanyHasUpdates ({ commit }, input) {
    commit('setCompanyHasUpdates', input)
  },
  setOverviewHasUpdates ({ commit }, input) {
    commit('setOverviewHasUpdates', input)
  },
  setContactsHasUpdates ({ commit }, input) {
    commit('setContactsHasUpdates', input)
  },
  setFinancialDataHasUpdates ({ commit }, input) {
    commit('setFinancialDataHasUpdates', input)
  },
  setSupportingSourcesHasUpdates ({ commit }, input) {
    commit('setSupportingSourcesHasUpdates', input)
  },
  setAccreditationsHasUpdates ({ commit }, input) {
    commit('setAccreditationsHasUpdates', input)
  },
  setSustainabilityGoalsHasUpdates ({ commit }, input) {
    commit('setSustainabilityGoalsHasUpdates', input)
  },
  setTealium ({ commit }, input) {
    commit('setTealium', input)
  },
  setNotificationPosition ({ commit }, input) {
    commit('setNotificationPosition', input)
  },
  setNotificationWidth ({ commit }, input) {
    commit('setNotificationWidth', input)
  },
  setReportLogs ({ commit }, input) {
    commit('setReportLogs', input)
  }
}

const mutations = {
  setReport (state, input) {
    if (input === 'clear') {
      input = {
        id: '',
        date_updated: '',
        user_created: {},
        company_name: '',
        short_description: '',
        customer_serial_number: '',
        region: {},
        industry_sector: {},
        company_size: '',
        company_location: '',
        company_website: '',
        company_linkedin_profile: '',
        sustainability_goals: [],
        contacts: [],
        financial_records: [],
        supporting_sources: [],
        accreditations: [],
        overview_notes: []
      }
      state.reportLogs = []
    }
    state.report = input
    state.reportID = input.id
    state.userCreated = input.user_created
    state.lastModified = input.date_updated
    state.companyName = input.company_name
    state.shortDescription = input.short_description
    state.customerSerialNumber = input.customer_serial_number
    state.reportRegion = input.region
    state.reportIndustry = input.industry_sector
    state.reportCompanySize = input.company_size
    state.companyLocation = input.company_location
    state.companyWebsite = input.company_website
    state.companyLinkedinProfile = input.company_linkedin_profile
    state.reportSustainabilityGoals = input.sustainability_goals
    state.reportContacts = input.contacts
    state.reportFinancialRecords = input.financial_records
    state.reportSupportingSources = input.supporting_sources
    state.reportAccreditations = input.accreditations
    state.activeGoal = 0
    state.activeContact = 0
    state.activeSupportingSource = 0
    state.activeFinancialRecord = 0
    state.activeAccreditation = 0

    state.overviewHasUpdates = false
    state.companyHasUpdates = false
    state.contactsHasUpdates = false
    state.financialDataHasUpdates = false
    state.supportingSourcesHasUpdates = false
    state.accreditationsHasUpdates = false
    state.sustainabilityGoalsHasUpdates = false
  },
  setMobileView (state, input) {
    state.mobileView = input
  },
  setActiveGoal (state, input) {
    state.activeGoal = input
  },
  setActiveContact (state, input) {
    state.activeContact = input
  },
  setActiveFinancialRecord (state, input) {
    state.activeFinancialRecord = input
  },
  setActiveSupportingSource (state, input) {
    state.activeSupportingSource = input
  },
  setActiveAccreditation (state, input) {
    state.activeAccreditation = input
  },
  setCompanyName (state, input) {
    state.companyName = input
    state.overviewHasUpdates = true
  },
  setShortDescription (state, input) {
    state.shortDescription = input
    state.overviewHasUpdates = true
  },
  setCustomerSerialNumber (state, input) {
    state.customerSerialNumber = input
    state.companyHasUpdates = true
  },
  setReportRegion (state, input) {
    state.reportRegion = input
    state.overviewHasUpdates = true
  },
  setRegions (state, input) {
    state.regions = input
  },
  setIndustrySectors (state, input) {
    state.industrySectors = input
  },
  setCompanySizes (state, input) {
    state.companySizes = input
  },
  setSustainabilityGoalOptions (state, input) {
    state.sustainabilityGoalOptions = input
  },
  setReportIndustry (state, input) {
    state.reportIndustry = input
    state.overviewHasUpdates = true
  },
  setReportCompanySize (state, input) {
    state.reportCompanySize = input
    state.companyHasUpdates = true
  },
  setCompanyLocation (state, input) {
    state.companyLocation = input
    state.companyHasUpdates = true
  },
  setCompanyWebsite (state, input) {
    state.companyWebsite = input
    state.companyHasUpdates = true
  },
  setCompanyLinkedinProfile (state, input) {
    state.companyLinkedinProfile = input
    state.companyHasUpdates = true
  },
  setReportSustainabilityGoals (state, input) {
    state.reportSustainabilityGoals = input
  },
  setReportContacts (state, input) {
    state.reportContacts = input
  },
  setReportFinancialRecords (state, input) {
    state.reportFinancialRecords = input
  },
  setReportSupportingSources (state, input) {
    state.reportSupportingSources = input
  },
  setReportAccreditations (state, input) {
    state.reportAccreditations = input
  },
  setReportOverviewNotes (state, input) {
    state.reportOverviewNotes = input
  },
  setCompanyHasUpdates (state, input) {
    state.companyHasUpdates = input
  },
  setOverviewHasUpdates (state, input) {
    state.overviewHasUpdates = input
  },
  setContactsHasUpdates (state, input) {
    state.contactsHasUpdates = input
  },
  setFinancialDataHasUpdates (state, input) {
    state.financialDataHasUpdates = input
  },
  setSupportingSourcesHasUpdates (state, input) {
    state.overviewHasUpdates = input
  },
  setAccreditationsHasUpdates (state, input) {
    state.accreditationsHasUpdates = input
  },
  setSustainabilityGoalsHasUpdates (state, input) {
    state.sustainabilityGoalsHasUpdates = input
  },
  setTealium (state, input) {
    state.tealium = input
  },
  setNotificationWidth (state, input) {
    state.notificationWidth = input
  },
  setNotificationPosition (state, input) {
    state.notificationPosition = input
  },
  setReportLogs (state, input) {
    state.reportLogs = input
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
