<template>
    <div class='text-wrapper' :class="{ error: showError }">
        <ToolTip v-if="toolTip" :msg="toolTip" class="top-neg-5"/>
        <label for="{{ name }}">{{ label }}</label>
          <input 
            @blur="validateField"
            class="form-control"
            :type="type" 
            id="{{ name }}" 
            name="{{ name }}" 
            :value="modelValue" 
            @keyup="textUpdate"
            aria-label="{{ label }}"
            :maxlength="maxCharachterCount > 0 ? maxCharachterCount : ''"
            :pattern="pattern"
            :placeholder="placeHolder"
        >
        <p v-if="charactersRemaining && showCount" class="charachter-count">You have {{ charactersRemaining }} characters remaining.</p>
        <p v-if="charactersRemaining === 0 && showCount" class="charachter-count">You have no characters remaining.</p>
        <p v-if="errorMessage && showError" class="error-message">{{ errorMessage }}</p>
    </div>
</template>
<script>
import ToolTip from '../ToolTip'
import validator from 'validator'

export default {
  name: 'Text',
  components: {
    ToolTip
  },
  props: {
    maxCharachterCount: {
      type: Number,
      default: 0
    },
    textInput: String,
    label: String,
    modelValue: {
      type: String,
      default: ''
    },
    showCount: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    name: String,
    toolTip: String,
    pattern: String,
    placeHolder: String,
    errorMessage: String,
  },
  data() {
    return {
      text: '',
      showError: false
    }
  },
  methods: {
    textUpdate (e) {
      this.$emit('update:modelValue', e.target.value)
    },
    validateField () {
      if (this.errorMessage && this.errorMessage.length ) {
        let showError = false
        switch (this.type) {
          case 'url':
            showError = this.modelValue && this.modelValue.length ? !validator.isURL(this.modelValue) : false
            break;
          case 'email':
            showError = this.modelValue && this.modelValue.length ? !validator.isEmail(this.modelValue) : false
            break;
          default:
            break;
        }
        this.showError = showError
      }
    },
    tryRemoveError () {
      if (this.errorMessage && this.errorMessage.length && this.showError ) {
        let showError = false
        switch (this.type) {
          case 'url':
            showError = this.modelValue && this.modelValue.length ? !validator.isURL(this.modelValue) : false
            break;
          case 'email':
            showError = this.modelValue && this.modelValue.length ? !validator.isEmail(this.modelValue) : false
            break;
          default:
            break;
        }
        this.showError = showError
      }
    }
  },
  created () {
    this.validateField()
  },
  updated () {
    this.tryRemoveError()
  },
  computed: {
    charactersRemaining: function () {
      if (this.maxCharachterCount > 0 && this.modelValue) {
        return this.maxCharachterCount - this.modelValue.length
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.text-wrapper {
  position: relative;
    width: 100%;
    margin-bottom: (24/$base-font)*1rem!important;
  input { 
    width: 100%;
    &.validate-error {
      border-color:  $color-17;
    }
  }
  .charachter-count {
    font-size: (14/$base-font)*1rem;
    left: 0;
    color: $color-22;
    margin: 0;
  }
  &.error {

    .error-message {
      position: relative;
      font-size: (14/$base-font)*1rem;
      left: 0;
      color: $color-24;
      margin: 0;
      padding-left: 18px;
      &:before {
        content: '';
        background: url('../../assets/images/error-icon.svg');
        width: 15px;
        height:15px;
        position: absolute;
        top: 2px;
        left: 0;
      }
    }
  }
  
}
</style>
