
<template>
    <main class="container">
      <div class="row">
        <div class="col">
          <h2>Authenticating</h2>
        </div>
      </div>
    </main>
</template>

<script>
  import { getCookie, setCookie, eraseCookie } from '../services/helpers'
  import { mapGetters, mapActions } from 'vuex'
  import { oauthUrl } from '../services/api'

  export default {
    // https://adsk-sustainability.torpedo.agency/api/auth/login/autodesk?redirect=https://adsk-sustainability.torpedo.agency/auth
    // http://localhost:8055/auth/login/autodesk?redirect=http://localhost:8080/auth
    name: 'Auth',
    methods: {
      ...mapActions(['oauthLogIn']),
      async logIn () {
        await this.oauthLogIn().then(() => {
          // delete any previous fail cookies
          eraseCookie('oauth_retry')
          eraseCookie('reload_retry')

          // incase of previous oauth error a short term cookie may be avialable so we can go directly back to where the auth error initally occured
          const returnLocation = getCookie('oauth_return_location')
          if (returnLocation && returnLocation.length) {
            this.$router.push(returnLocation)
          } else {
            this.$router.push({ name: 'Home' })
          }
        }, error => {
          console.error("oauth", error)
          const oauthRetry = getCookie('oauth_retry')
          const reloadRetry = getCookie('reload_retry')

          if (!reloadRetry || reloadRetry !== 'true') {
            setCookie('reload_retry', 'true', 0.0006)
            // retry once by setting a cookie - if it already exists we don't retry
            window.location.reload()
          } else if (!oauthRetry || oauthRetry !== 'true') {
            // we've already tried reloading - try the autodesk auth again
            setCookie('oauth_retry', 'true', 0.0006)
            window.location.href = oauthUrl
          } else {
            this.$router.push({ name: 'AuthFailed' })
          }
        })
      } 
    },
  
    mounted () {

    },
    created () {
      this.logIn()
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">

</style>