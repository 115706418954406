<template>
  <div class="page-wrapper">
    <!-- <component :is="'script'" :src="tealium" type="application/javascript"></component> -->
    <Header />
    <main class="container">
      <div class="row">
        <div class="col topBlock">
          <h1>User Profile</h1>
        </div>
      </div>
    </main>
    <div class="container">
      <div class="row">
        <div class="col-md-4 offset-md-3 topBlock">
          <h5 class="standfirst">Email: {{ email }}</h5>
        </div>
        <div class="col-md-4 topBlock">
          <template v-if="firstName === null">
          </template>
          <template v-else>
            <h5 class="standfirst left">
              First Name:
              <span class="name">{{ firstName }}</span>
            </h5>
            <h5 class="standfirst left">
              Surname:
              <span class="name">{{ lastName }}</span>
            </h5>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
  name: 'user',
  components: {
    Header,
  },
  computed: {
    firstName: function () {
      return this.$store.getters.user.first_name
    },
    lastName: function () {
      return this.$store.getters.user.last_name
    },
    email: function () {
      return this.$store.getters.user.email
    },
  },
}
</script>

<style scoped>
.name {
  text-transform: capitalize;
}
.left {
  text-align: left;
}
.page-wrapper{
    height: 100vh;
}
</style>
