import { axiosInstance, axiosRefresh, oauthUrl } from './api'
import { setCookie } from './helpers'
// import router from '../router'

const setupInterceptors = () => {
  // for multiple requests
  let isRefreshing = false
  let failedQueue = []

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        console.log('queue process reject', error)
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })
    failedQueue = []
  }

  axiosInstance.interceptors.response.use((res) => {
    return res
  },

  async (error) => {
    const originalRequest = error.config
    originalRequest._retry = originalRequest._retry > 0 ? originalRequest._retry : 0
    if ((error.response.status === 401 || error.response.status === 403 || error.response.status === 503) && originalRequest._retry < 3) {
      // push promise to redo api call to array
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          return axiosInstance(originalRequest)
        }).catch(err => {
          console.log('request failing from promise', err)
          return Promise.reject(err)
        })
      }

      // some causing 401 response can be sent BEFORE new token is issued and return AFTER a new token is just issued - check if token has changed
      /*       const checkAuth = 'Bearer ' + getCookie('tdv_access_token')
      if (originalRequest.headers.Authorization !== checkAuth) {
        console.log('check original bearer from token:', originalRequest.headers.Authorization)
        originalRequest.headers['Authorization'] = checkAuth
        return Promise.resolve(axiosInstance(originalRequest))
      } */

      originalRequest._retry++
      // first one set refreshing to true so any more go to queue
      isRefreshing = true
      return new Promise(function (resolve, reject) {
        axiosRefresh.post('auth/refresh', {}, { withCredentials: true })
          .then((response) => {
            const accessToken = response.data.data.access_token
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
            setCookie('tdv_access_token', accessToken, 1)
            processQueue(null, accessToken)
            originalRequest.headers['Authorization'] = 'Bearer ' + accessToken
            console.log('reauthorized')
            resolve(axiosInstance(originalRequest))
          })
          .catch((error) => {
            console.log('auth/refresh error', error)
            if (error.response) {
              console.log('auth/refresh error', error.response)
            }
            processQueue(error, null)
            const currentLocation = window.location.pathname
            // 30 second cookie - if set auth will try to use to redirect to original page
            setCookie('oauth_return_location', currentLocation, 0.00037)
            window.location.href = oauthUrl
            reject(error)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  })
}

export default setupInterceptors
