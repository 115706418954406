<template>
  <div class="page-wrapper">
    <!-- <component :is="'script'" :src="tealium" type="application/javascript"></component> -->
    <Header :simpleHeader="true"/>
    <main class="container">
      <div class="row">
        <div class="col">
          <h2>You have been logged out of the Sustainability Tool</h2>
          <ButtonAutodesk class="btn btn--autodesk-dark" label="Log back in" :href="oauthUrl"/>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Header from'../components/Header'
  import Footer from'../components/Footer'
  import ButtonAutodesk from '../components/buttons/ButtonAutodesk'
  import { oauthUrl } from '../services/api'

  export default {
    name: 'AuthFailed',
    components: {
      Header,
      Footer,
      ButtonAutodesk
    },
    data () {
      return {
        oauthUrl: String
      }
    },
    created () {
      this.oauthUrl = oauthUrl
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  
</style>