<template>
  <div class='page-wrapper'>
    <Header />
    <main class='container'>
      <div class="row">
        <div class="col-6">
          <h2>Audit log</h2>
          <h6 class="small">Track and find changes made to every report. Add filters to your results to narrow down your search. </h6>
        </div>
      </div>
      <form @submit.prevent="save" class='autodesk-form col-lg-6'>
        <div class="row">
          <div class="col-md-2 mt-4 filter-by">Filter by:</div>
          <div class="col-5">
            <div class='form-group log-select'>
              <Select 
                label="Report name" 
                name="report-name" 
                :multiple="false" 
                placeholder="Type to filter"
                v-model="reportSelected"
                @search="searchReports"
                @click="searchReports"
                :options="reportOptions" />
            </div>
          </div>
          <div class="col-md-1">
            <ButtonAutodesk class="btn btn--autodesk-dark ms-auto mt-4" label="Apply" @clicked="fetchLogs"/>
          </div>
        </div>
      </form>

      <div class='row'>
        <div class='col-sm-12'>
          <AuditList :logEntries="logEntries" />
        </div>
      </div>
      <Pagination :page="page" :perPage="perPage" :items="items" @pageChange="setPage" />
    </main>
    <Footer />
  </div>
</template>

<script>
  import Footer from '../components/Footer'
  import Header from '../components/Header'
  import Pagination from '../components/Pagination'
  import AuditList from '../components/AuditList'
  import Select from '../components/forms/Select'
  import ButtonAutodesk from '../components/buttons/ButtonAutodesk'
  import { mapGetters } from 'vuex'
  import { axiosInstance } from '../services/api'

  export default {
    name: 'AuditLog',
     components: {
        Footer,
        Header,
        AuditList,
        Pagination,
        ButtonAutodesk,
        Select
    },
    data() {
      return {
        reportOptions: [],
        reportSelected: {},
        logEntries: [],
        page: 1,
        perPage: 12,
        items: ''
      }
    },
    methods: {
      setPage(payload) {
        this.page = payload.page
        this.fetchLogs()
      },
      setOffset(offset) {
        if (this.page === 1) {
          offset = 0
        } else {
          offset = this.page * this.perPage - this.perPage
        }
        return offset
      },
      async searchReports(search) {
        search = ''
        if (search.length) { 
          search =`&filter[company_name][_contains]=${search}`
        }
        try {
          let response = await axiosInstance.get(
            `items/reports?sort[]=company_name${search}`
          )
          let searchResults = response.data.data
          let reportArray = searchResults.map((report) => {
            const item = {
              label: report.company_name,
              id: report.id
            }
            return item
          }) 
          // reportArray= reportArray.slice(0, 5)
          this.reportOptions = reportArray
        } catch (error) {
          console.log(error.response)
        }
      },

      async fetchLogs () {
        let filter = ''
        if (this.reportSelected && this.reportSelected.id) {
          filter = `filter[report][_eq]=${this.reportSelected.id}&`
        }
        const offset = this.setOffset()
        let response = await axiosInstance.get(
            `items/audit_log?${filter}sort[]=-date_created&fields=action,date_created,report.id,report.company_name,report.user_created.first_name,report.user_created.last_name,user_created.first_name,user_created.last_name&offset=${offset}&limit=${this.perPage}&meta=filter_count`
          ) 
        const logEntries = response.data.data
        this.logEntries = logEntries
        this.items = response.data.meta.filter_count
      }
    },
    created () {
      this.fetchLogs()
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  .filter-by {
    width: 90px;
  }
</style>