<template>
  <div class="page-wrapper">
    <!-- <component :is="'script'" :src="tealium" type="application/javascript"></component> -->
    <Header :simpleHeader="true"/>
    <main class="container">
      <div class="row">
        <div class="col">
          <h2>Authorization Failed</h2>
          <h5>Autodesk employees and agencies with existing access may sign in using their Autodesk Account. </h5>
          <h5>Agencies without access must request an account.</h5>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Header from'../components/Header'
  import Footer from'../components/Footer'

  export default {
    name: 'AuthFailed',
    components: {
      Header,
      Footer
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  
</style>