<template>
  <div id="sticky-list">
    <div class='list-title d-flex'>
      <h5>{{ listTitle }}</h5>
      <ButtonAutodesk class="btn btn--autodesk-outline btn--autodesk--icon ms-auto" label="" @clicked="createNewItem">
        <img src='../../assets/images/plus.svg' alt=''>
      </ButtonAutodesk>
    </div>
    
    <ul v-if="stage === 'company'" class="items-list">
        <li class="item-button" v-for="(goal, index) in reportSustainabilityGoals" :key="goal.id">
          <a @click="setActive(index)" :class="{ isActive: index === activeGoal && reportSustainabilityGoals.length > 1 && showActive}">
            <span class="button-content" v-if="goal.sustainability_goal">
              <span class="goal-number" v-if="goal.sustainability_goal.number">{{ goal.sustainability_goal.number }}. </span> 
              <span class="goal-label">{{ goal.sustainability_goal.label }}</span>
            </span>
            <span v-else>Create goal</span>
            <img src='../../assets/images/arrow-right.svg' alt=''>
          </a>
        </li>
    </ul>
    <ul v-if="stage === 'contacts'" class="items-list">
        <li class="item-button" v-for="(contact, index) in reportContacts" :key="contact.id">
            <a @click="setActive(index)" :class="{ isActive: index === activeContact && reportContacts.length > 1 && showActive}">
              <span class="button-content" v-if="contact.name">{{ contact.name }}</span>
              <span class="button-content" v-else>Create contact</span>
              <img src='../../assets/images/arrow-right.svg' alt=''>
            </a>
        </li>
    </ul>
    <ul v-if="stage === 'financial-data'" class="items-list">
        <li class="item-button" v-for="(record, index) in reportFinancialRecords" :key="record.id">
            <a @click="setActive(index)" :class="{ isActive: index === activeFinancialRecord && reportFinancialRecords.length > 1 && showActive}">
              <span class="button-content" v-if="record.title">{{ record.title }}</span>
              <span class="button-content" v-else>Create financial data</span>
              <img src='../../assets/images/arrow-right.svg' alt=''>
            </a>
        </li>
    </ul>
    <ul v-if="stage === 'supporting-sources'" class="items-list">
        <li class="item-button" v-for="(source, index) in reportSupportingSources" :key="source.id">
            <a @click="setActive(index)" :class="{ isActive: index === activeSupportingSource && reportSupportingSources.length > 1 && showActive}">
              <span class="button-content" v-if="source.title">{{ source.title }}</span>
              <span class="button-content" v-else>Create supporting source</span>
              <img src='../../assets/images/arrow-right.svg' alt=''>
            </a>
        </li>
    </ul>
    <ul v-if="stage === 'accreditations'" class="items-list">
        <li class="item-button" v-for="(item, index) in reportAccreditations" :key="item.id">
            <a @click="setActive(index)" :class="{ isActive: index === activeAccreditation && reportAccreditations.length > 1 && showActive}">
              <span class="button-content" v-if="item.title">{{ item.title }}</span>
              <span class="button-content" v-else>Create accreditation</span>
              <img src='../../assets/images/arrow-right.svg' alt=''>
            </a>
        </li>
    </ul>
    <!-- <button class="btn btn--autodesk-outline" @click="exportCSV" type="button">Export list</button> -->
    
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { axiosInstance, createNewAuditLogEntry } from '../../services/api'
  import { CSVExport } from '../../services/CSVExport'
  import ButtonAutodesk  from '../buttons/ButtonAutodesk'

  export default {
    name: 'ReportButtonList',
    components: {
      ButtonAutodesk
    },
    props: {
      listTitle: String,
      stage: String,
    },
    data() {
      return {
        showActive: false
      }
    },
    methods: {
      ...mapActions(['setActiveGoal', 'setActiveContact', 'setActiveFinancialRecord', 'setActiveSupportingSource', 'setActiveAccreditation']),
      setActive (item) {
        this.showActive = true
        switch (this.stage) {
          case 'company': {
            this.setActiveGoal(item)
            this.scrollToItem()
            break;
          }
          case 'contacts': {
            this.setActiveContact(item)
            this.scrollToItem()
            break;
          }
          case 'financial-data': {
            this.setActiveFinancialRecord(item)
            this.scrollToItem()
            break;
          }
          case 'supporting-sources': {
            this.setActiveSupportingSource(item)
            this.scrollToItem()
            break;
          }
          case 'accreditations': {
            this.setActiveAccreditation(item)
            this.scrollToItem()
            break;
          }
          default:
            break;
        }
      },
      async createNewItem () {
        try {
          switch (this.stage) {
            case 'company': {
              // if the initial goal is still empty don't create a new one 
              const itemTitle = this.reportSustainabilityGoals[0].sustainability_goal
              if (this.reportSustainabilityGoals.length !== 1 || (itemTitle && itemTitle.slug !== 'select-a-sustainability-goal')) {
                const response = await axiosInstance.post('items/sustainability_goals')
                const updateList = [ response.data.data, ...this.reportSustainabilityGoals]
                this.reportSustainabilityGoals = updateList
                this.$notify({ 
                  title: "Goal created",
                  group: 'custom-notification'
                })
                this.setActiveGoal(0)
                createNewAuditLogEntry (this.$store.getters.reportID, 'Sustainability goal created', false)
              }
              this.scrollToItem()
              break;
            }
            case 'contacts': {
              const itemTitle = this.reportContacts[0].name
              if (this.reportContacts.length !== 1 || (itemTitle !== '' && itemTitle !== null)) {
                const response = await axiosInstance.post('items/contacts')
                const updateList = [ response.data.data, ...this.reportContacts]
                this.reportContacts = updateList
                this.$notify({ 
                  title: "Contact created",
                  group: 'custom-notification'
                })
                this.setActiveContact(0)
                createNewAuditLogEntry (this.$store.getters.reportID, 'Contact created', false)
              }
              this.scrollToItem()
              break;
            }
            case 'financial-data': {
              const itemTitle = this.reportFinancialRecords[0].title
              if (this.reportFinancialRecords.length !== 1 || (itemTitle !== '' && itemTitle !== null)) {
                const response = await axiosInstance.post('items/financial_records')
                const updateList = [ response.data.data, ...this.reportFinancialRecords]
                this.reportFinancialRecords = updateList
                this.$notify({ 
                  title: "Financial data created",
                  group: 'custom-notification'
                })
                this.setActiveFinancialRecord(0)
                createNewAuditLogEntry (this.$store.getters.reportID, 'Financial data created', false)
              }
              this.scrollToItem()
              break;
            }
            case 'supporting-sources': {
              const itemTitle = this.reportSupportingSources[0].title
              if (this.reportSupportingSources.length !== 1 || (itemTitle !== '' && itemTitle !== null)) {
                const response = await axiosInstance.post('items/supporting_sources')
                const updateList = [response.data.data, ...this.reportSupportingSources]
                this.reportSupportingSources = updateList
                this.$notify({ 
                  title: "Supporting source created",
                  group: 'custom-notification'
                })
                this.setActiveSupportingSource(0)
                createNewAuditLogEntry (this.$store.getters.reportID, 'Supporting source created', false)
              }
              this.scrollToItem()
              break;
            }
            case 'accreditations': {
              const itemTitle = this.reportAccreditations[0].title
              if (this.reportAccreditations.length !== 1 || (itemTitle !== '' && itemTitle !== null)) {
                const response = await axiosInstance.post('items/accreditations')
                const updateList = [ response.data.data, ...this.reportAccreditations]
                this.reportAccreditations = updateList
                this.$notify({ 
                  title: "Accreditation created",
                  group: 'custom-notification'
                })
                this.setActiveAccreditation(0)
                createNewAuditLogEntry (this.$store.getters.reportID, 'Accreditation created', false)
              }
              this.scrollToItem()
              break;
            }
            default:
              break;
          }

        } catch (error) {
          console.log(error.response);
          this.$notify({ 
            title: "File failed to create item",
            group: 'custom-notification' 
          })
        }
      },
      scrollToItem () {
        this.$emit('scrollToItem')
      },
      exportCSV () {
        // dont yet know the user for items just added - can get current user?
        function checkUserCreated (item) {
          const userCreated = item.user_created && item.user_created.first_name ? `${item.user_created.first_name} ${item.user_created.last_name}` : `${this.$store.getters.user.first_name} ${this.$store.getters.user.last_name}` 
          return userCreated
        }
        function checkUserUpdated (item) {
          const userUpdated = item.user_updated && item.user_updated.first_name ? `${item.user_updated.first_name} ${item.user_updated.last_name}` : ''
          return userUpdated
        }
        
        let formattedData = []
        let sectionTitle
        
        switch (this.stage) {
          case 'company': {
            sectionTitle = 'Sustainability goals'
            
            formattedData = this.reportSustainabilityGoals.map(item => {
              const userCreated = checkUserCreated(item)
              const userUpdated = checkUserUpdated(item)
              const formattedGoal = {
                'Sustainability Goal': item.sustainability_goal.label,
                'URL': item.url,
                'Additional Information': item.additional_information,
                'User created': userCreated,
                'User updated': userUpdated,
                'Date created': item.date_created,
                'Date updated': item.date_updated
              }
              return formattedGoal
            })
            break
          }
          case 'contacts': {
            sectionTitle = 'Contacts'
            formattedData = this.reportContacts.map(item => {
              const userCreated = checkUserCreated(item)
              const userUpdated = checkUserUpdated(item)
              const formattedGoal = {
                'Name': item.name,
                'Job title': item.job_title,
                'Telephone number': item.telephone_number,
                'Email': item.email,
                'Linkedin profile': item.linkedin_profile,
                'Last contacted': item.last_contacted,
                'Additional information': item.additional_information,
                'User created': userCreated,
                'User updated': userUpdated,
                'Date created': item.date_created,
                'Date updated': item.date_updated
              }
              return formattedGoal
            })
            break;
          }
          case 'financial-data': {
            sectionTitle = 'Financial data'
            formattedData = this.reportFinancialRecords.map(item => {
              const userCreated = checkUserCreated(item)
              const userUpdated = checkUserUpdated(item)
              const formattedGoal = {
                'Title': item.title,
                'URL': item.url,
                'Additional information': item.additional_information,
                'User created': userCreated,
                'User updated': userUpdated,
                'Date created': item.date_created,
                'Date updated': item.date_updated
              }
              return formattedGoal
            })
            break
          }
          case 'supporting-sources': {
            sectionTitle = 'Supporting sources'
            formattedData = this.reportSupportingSources.map(item => {
              const userCreated = checkUserCreated(item)
              const userUpdated = checkUserUpdated(item)
              const formattedGoal = {
                'Title': item.title,
                'URL': item.url,
                'Additional information': item.additional_information,
                'Publication date': item.publication_date,
                'User created': userCreated,
                'User updated': userUpdated,
                'Date created': item.date_created,
                'Date updated': item.date_updated
              }
              return formattedGoal
            })
            break
          }
          case 'accreditations': {
            sectionTitle = 'Accreditations'
            formattedData = this.reportAccreditations.map(item => {
              const userCreated = checkUserCreated(item)
              const userUpdated = checkUserUpdated(item)
              const formattedGoal = {
                'Title': item.title,
                'URL': item.url,
                'Additional information': item.additional_information,
                'Publication date': item.publication_date,
                'User created': userCreated,
                'User updated': userUpdated,
                'Date created': item.date_created,
                'Date updated': item.date_updated
              }
              return formattedGoal
            })
            
            break
          }
          default:
            break
        }
        CSVExport(formattedData, this.$store.getters.companyName, sectionTitle)
      }
    },

    computed: {
      ...mapGetters(['activeGoal', 'activeContact', 'activeFinancialRecord', 'activeSupportingSource', 'activeAccreditation']),
      reportSustainabilityGoals: {
        get () {
          return this.$store.getters.reportSustainabilityGoals
        },
        set (value) {
          this.$store.commit('setReportSustainabilityGoals', value)
        }
      },
      reportContacts: {
        get () {
          return this.$store.getters.reportContacts
        },
        set (value) {
          this.$store.commit('setReportContacts', value)
        }
      },
      reportFinancialRecords: {
        get () {
          return this.$store.getters.reportFinancialRecords
        },
        set (value) {
          this.$store.commit('setReportFinancialRecords', value)
        }
      },
      reportSupportingSources: {
        get () {
          return this.$store.getters.reportSupportingSources
        },
        set (value) {
          this.$store.commit('setReportSupportingSources', value)
        }
      },
      reportAccreditations: {
        get () {
          return this.$store.getters.reportAccreditations
        },
        set (value) {
          this.$store.commit('setReportAccreditations', value)
        }
      },
    }
    
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

#sticky-list {
  position: sticky;
  top: 150px;
}
.list-title {
  align-items: center;
  margin-bottom: 1rem;
  h5 {
    margin-bottom: 0;
  }
}
.items-list {
  padding-left: 0;
  .item-button {
    list-style: none;
    width: 100%;
    border-top: 1px solid $color-16;
    cursor: pointer;
    a {
      position: relative;
      width: 100%;
      display: flex;
      text-decoration: none;
      padding: 1rem 0;
      background: inherit;
      display: flex;
      color: $brand-color-1;
      span.button-content {
        display: flex;
        padding-right: (24/$base-font)*1rem;
        .goal-number {
          padding-right: (2/$base-font)*1rem;
          min-width: 20px;

        }
      }
      &:hover {
          background: $gradient-3;
      }
      &:active {
          background: $gradient-4;
      }
      &:focus {
          outline: 1.5px solid $color-17;
          box-shadow: none;
          padding-left: .5rem;
      }
      img {
        position: absolute;
        top: 1rem;
        right:.2rem;
      }
      &.isActive {
        background: $gradient-4;
      }
    }
    &:first-of-type {
      border-top: 0px solid $color-16;
    }
  }
}
  
</style>
