<template>
  <div class='overview-notes-wrapper'>
    <div class='container'>
      <div class='row'>
        <div class='col-sm-12 offset-xl-3 col-lg-8 col-xl-5 offset-lg-2 notes-row'>
          <h3>Overview Notes</h3>
          <p>Communicate with your co-authors internally. This section will not be included in the final report.</p>
          <form @submit.prevent="save" class='autodesk-form'>
            <div class="form-group notes-input">
              <div class='textarea-container'>
                <TextArea 
                  label="Add a comment"
                  name="notes-input"
                  v-model="comment"
                  :height="40"
                  />
              </div>
              <ButtonAutodesk class="btn btn--autodesk-outline" label="Send" @clicked="addNote"/>
            </div>
          </form>
          <div id='latest-notes'>
            <p class="label">Latest comments <span>Newest first</span></p>
            <ul class="note-list">
              <li class="note" v-bind:class="{ allDisplayed : notesDisplayCount >= availableNotes }" v-for="note in reportOverviewNotes" :key="note.id">
                <p class="note-info">{{ note.user_created.first_name }} {{ note.user_created.last_name }}   <span> {{ note.date_created }}</span></p>
                <p class="note-comment">{{ note.comment }}</p>
              </li>
            </ul>
            <ButtonAutodesk v-if="notesDisplayCount < availableNotes" class="btn btn--autodesk-outline" label="View more" @clicked="viewMore"/>
          </div>
        </div>
      </div>
      <div class='row audit-row'>
        <div class='col'>
          <AuditList class="report-logs" type="company"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TextArea from '../forms/TextArea'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import { axiosInstance, createNewNote } from '../../services/api'
  import { format } from 'date-fns'
  import AuditList from '../AuditList'

  export default {
    name: 'ReportNotes',
    components: {
      TextArea,
      ButtonAutodesk,
      AuditList
    },
    props: {
      
    },
    data() {
      return {
        comment: '',
        notesDisplayCount: 3,
        availableNotes: 0
      }
    },
    methods: {
      viewMore () {
        this.notesDisplayCount = this.notesDisplayCount + 10
        this.fetchOverviewNotes()
      },
      addNote () {
        createNewNote(this.$store.getters.reportID, this.comment).then(() => {
          this.fetchOverviewNotes()
          this.comment = ''
        })
      },
      async fetchOverviewNotes () {
        const response = await axiosInstance.get(
          `items/overview_notes?limit=${this.notesDisplayCount}&filter[o2m]=${this.$store.getters.reportID}&sort[]=-date_created&meta=filter_count&fields=*.*,user_created.*`
          )
        this.reportOverviewNotes = response.data.data
        this.availableNotes = response.data.meta.filter_count
      }
    },
    mounted () {
      this.fetchOverviewNotes()
    },
    computed: {
      reportOverviewNotes: {
        get () {
          let notes = this.$store.getters.reportOverviewNotes
          notes.map(note => {
            try {
              let date = note.date_created
              date = new Date(date)
              let dateFormatted = format(date,'do MMM yyyy')
              note.date_created = dateFormatted
              return note
            } catch (err) {
              console.log('notes err')
            }

          })
          return notes
        },
        set (value) {
          this.$store.commit('setReportOverviewNotes', value)
        }
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.overview-notes-wrapper {
  padding: (48/$base-font)*1rem 0 0;
  background-color: $color-1;
  border-top: 1px solid $color-16;
  .audit-row {
    padding-bottom: 6.25rem;
  }
  .notes-row {
    padding-bottom: 6.25rem;

    .notes-input {
      display: flex;
      .textarea-container{
        margin-right: .5rem!important;
        width: 100%;
      }
      button {
        height: 44px;
        margin-top: (24/$base-font)*1rem;
      }
    }

    #latest-notes {
      .label {
        font-size: (12/$base-font)*1rem;
        margin-bottom: (4/$base-font)*1rem;
        span {
          color: $color-22;
        } 
      }
      ul.note-list {
        margin: 0;
        padding: 0;

        .note {
          border: 1px solid $color-16;
          list-style: none;
          padding:(17/$base-font)*1rem;
          margin: 0;
          border-bottom: 0;
          .note-comment {
            font-size: (14/$base-font)*1rem;
            margin-bottom: (4/$base-font)*1rem;
          }
          .note-info {
            font-size: (12/$base-font)*1rem;
            font-weight: 700;
            margin-bottom: (4/$base-font)*1rem;
            span {
              font-weight: 400;
              color: $color-22;
            } 
          }
          &:first-child {
            border-radius: 4px 4px 0 0;
          }
          &.allDisplayed {
            &:last-child {
              border-bottom: 1px solid $color-16;
              border-radius: 4px;
            }
          }
        }
        
      }
      button {
        border-radius: 0 0 4px 4px;
        width: 100%;
      }
    }
  }
}

</style>
