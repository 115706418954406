import axios from 'axios'
import { eraseCookie, setCookie, setFirstName, setLastName } from '../../services/helpers.js'
import { axiosInstance, axiosRefresh } from '../../services/api'

const state = {
  loggedIn: false,
  oauthed: false,
  user: {},
  environment: 'development'
}

const getters = {
  isAuthenticated: state => state.loggedIn,
  user: state => state.user,
  environment: state => state.environment
}

const actions = {
  setEnvironment ({ commit }, input) {
    commit('setEnvironment', input)
  },

  LogIn ({ commit }, input) {
    return new Promise((resolve, reject) => {
      // authenticate
      axios.post('auth/login', input, { withCredentials: true }).then((response) => {
        const accessToken = response.data.data.access_token
        setCookie('tdv_access_token', accessToken, 2)
        // get the current user
        axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + accessToken
        axiosInstance.get('users/me', { withCredentials: true }).then((response) => {
          const user = response.data.data
          commit('setUser', user)
          commit('setLoggedIn', true)
          resolve(response)
        }, error => {
          reject(error)
        })
      }, error => {
        reject(error)
      })
    })
  },
  async logOut ({ commit }) {
    await commit('setLoggedOut', false)
    await commit('setUser', {})
    await axiosRefresh.post('auth/logout', {}, { withCredentials: true })
    eraseCookie('tdv_access_token')
    eraseCookie('directus_refresh_token')
    return 'loggedout'
  },
  oauthLogIn ({ commit }) {
    return new Promise((resolve, reject) => {
      // authenticate
      axiosRefresh.post('auth/refresh', {}, { withCredentials: true })
        .then((response) => {
          const accessToken = response.data.data.access_token
          setCookie('tdv_access_token', accessToken, 1)
          axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + accessToken
          axiosInstance.get('users/me', { withCredentials: true }).then((response) => {
            const user = response.data.data
            const first_name = setFirstName(user.email)
            const last_name = setLastName(user.email)
            axiosInstance.patch('users/me', { first_name, last_name })
            commit('setUser', user)
            commit('setLoggedIn', true)
            resolve(response)
          }, error => {
            console.log('oauth login get user error', error)
            reject(error)
          })
        }, error => {
          console.log('oauth login refresh error', error)
          reject(error)
        })
    })
  },
  RequestPasswordReset ({ commit }, input) {
    return new Promise((resolve, reject) => {
      axios.post('auth/password/request', input, { withCredentials: true }).then((response) => {
        eraseCookie('tdv_access_token')
        commit('setLoggedOut', true)
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  },
  PasswordReset ({ commit }, input) {
    return new Promise((resolve, reject) => {
      axios.post('auth/password/reset', input).then((response) => {
        eraseCookie('tdv_access_token')
        commit('setLoggedOut', true)
        resolve(response)
      }, error => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setLoggedIn (state) {
    state.loggedIn = true
  },
  setUser (state, input) {
    state.user = input
  },
  setLoggedOut (state) {
    state.loggedIn = false
  },
  setEnvironment (state, input) {
    state.environment = input
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
