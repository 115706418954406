<template>
  <form
    @submit.prevent="search"
    class="search-form"
    :class="{ isNav: nav === true }"
  >
    <div class="input-group search-input">
      <input
        type="search"
        id="site-search"
        name="q"
        v-model="query"
        placeholder="Search for an existing report"
        class="search-field"
      />
      <ButtonAutodesk
        v-if="hasButton"
        class="btn btn--autodesk-dark ms-auto"
        label="Search"
        @clicked="search"
      />
    </div>
  </form>
</template>
<script>
import ButtonAutodesk from "../buttons/ButtonAutodesk";
export default {
  name: "SearchForm",
  components: {
    ButtonAutodesk,
  },
  props: {
    hasButton: Boolean,
    nav: Boolean,
  },
  data() {
    return {
      query: "",
      col: "",
    };
  },
  methods: {
    search() {
      this.$router.push(`/search/${this.query}`);
    },
    colour() {
      if (this.nav === true) {
        this.col = "rgba(255, 255, 255, 0.6)";
      } else {
        this.col = "rgba(0, 0, 0, 0.6)";
      }
    },
  },
  created() {
    this.colour();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.search-form {
  margin-top: 40px;
  display: inline-table;
}
.search-input {
  display: flex;
  position: relative;
  &::before {
    content: " ";
    position: absolute;
    background: url("../../assets/images/search-black.svg");
    left: 8px;
    top: 10px;
    width: 24px;
    height: 24px;
  }
}
.search-field {
  width: 566px;
  height: 44px;
  margin-right: 8px;
  background: $color-23;
  border: none;
  color: $color-22;
  border-radius: 4px;
  padding-left: 40px;

  &:hover {
    background: $color-27;
  }
  &:focus-visible,
  &:focus {
    outline: 2px solid $color-17;
  }
  &:active {
    outline: 1px solid $color-17;
  }
  @media screen and (max-width: 450px) {
    width: 250px;
  }
}
.input-group-text {
  background: $color-23;
  border: none;
  color: $color-22;
}
.isNav {
  margin: 0 0 0 46px;
  @media screen and (max-width: 450px) {
    margin: 0;
  }
  .search-input {
    &::before {
      background: url("../../assets/images/search.svg");
    }
    @media screen and (max-width: 450px) {
      margin: 0;
    }
  }
  .search-field {
    width: 360px;
    background: $color-14;
    color: $color-12;
    border-radius: 4px;
    border: none;
    margin-left: 0 !important;
    &:hover {
      background: $color-28;
    }
    &:focus-visible,
    &:focus {
      outline: 2px solid $color-17;
    }
    &:active {
      outline: 1px solid $color-17;
    }
    @media screen and (max-width: 450px) {
      width: 125px;
    }
  }
  .input-group {
    display: flex;
  }
}
</style>
