<template>
      <ul class="footer__nav">
        <!-- <li>
          <a class="footer__link" data-opt-in-preferences="" data-multi-optout-preferences="en" data-wat-linkname="manage-privacy-settings-footer-link" href="javascript:;">Privacy settings</a>
        </li> -->
        <li><a class="footer__link" href='https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement'>Privacy/Cookies</a></li>
        <li><a class="footer__link" href='https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement/autodesk-privacy-statement-2015-english#mixpanel'>About our Ads</a></li>
        <li><a class="footer__link" href='https://www.autodesk.com/company/legal-notices-trademarks'>Legal Notices and Trademarks</a></li>
        <li><a class="footer__link" href='https://www.autodesk.com/reportpiracy'>Report Noncompliance</a></li>
        <li><span class="footer__copyright" >© 2021 Autodesk Inc. All rights reserved.</span></li>
      </ul>

</template>

<script>

  export default {
      name: 'FooterNav',
      methods: {
        
      }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

ul.footer__nav {
  padding-top: (16/$base-font)*1rem;
  display: flex;
  flex-wrap: wrap;
  li {
    list-style: none;
    .footer__link, .footer__copyright {
      color: $color-2;
      font-size: (12 / $base-font) * 1rem;
      line-height: 1;
      margin-bottom: 0;
      padding: 0 .2rem;
      text-decoration: none;
      border-right: 1px solid $color-2;
    }
    .footer__copyright {
      border-right: 0;
    }
  }
}

</style>
