<template>
<div class='pagination-wrapper'>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item">
        <img class="arrow previous" 
          v-if="showPrevious"
          @click="$emit('pageChange', { page: page - 1})"
          src="../assets/images/caret-right.svg"
          alt="" />
      </li>
      <li class="page-item" v-for="pageNumber in setPages" :key="pageNumber">
        <button
          :class="{ active: pageNumber === page }"
          type="button"
          class="page-link"
          @click="$emit('pageChange', { page: pageNumber})"
        >
          {{ pageNumber }}
        </button>
      </li>
      <li class="page-item">

        <img class="arrow next" 
          v-if="showNext"
          @click="$emit('pageChange', { page: page + 1})"
          src="../assets/images/caret-right.svg"
          alt=""
          />
      </li>
    </ul>
  </nav>
  <div class='pageCount'>
    <p><span class="current">{{ currentItems }}</span><span class="total"> {{ items }}</span> results</p>
  </div>
</div>
</template>

<script>
export default {
  name: 'paginaton',
  props: {
    perPage: {
      type: Number,
      default: 4
    },
    items: {
      type: [String, Number],
      default: 0
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      pages: [],
    }
  },
  methods: {
    showPages () {

    }
  },
  computed: {
    // current pages available 
    setPages () {
      var pages = []
      let numberOfPages = Math.ceil(this.items / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        pages.push(index)
      }
      // slice 3 above and 3 below current page
      let below = this.page + -3 <= 1 ? 0 : this.page - 4
      let above = this.page + 3 >= numberOfPages ? numberOfPages + 1 : this.page + 3
      pages = pages.slice(below, above)
      return pages
    },
    // return text output
    currentItems () {
      let currentItems = ''
      if (this.perPage && this.page && this.items) {
        let finish = this.perPage * this.page
        finish = finish > this.items ? this.items : finish
        let start = finish - this.perPage + 1
        if (start < 1) {
          start = 1
        }
        currentItems = `${start} - ${finish} of `
        if (finish <= 1) {
          currentItems = ''
        }
      }
      return currentItems
    },
    showPrevious () {
      let showPrevious = false
      if (this.setPages && this.setPages.length && this.setPages[0] > 1) {
        showPrevious = true
      }
      return showPrevious
    },
    showNext () {
      let showNext = false
      if (this.setPages && this.setPages.length && this.setPages.at(-1) < Math.ceil(this.items / this.perPage)) {
        showNext = true
      }
      return showNext
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  display: flex;
  flex-direction: column;
}
.pageCount {
  display: flex;
  justify-content: center;
  font-size: (12/$base-font)*1rem;
  margin-top: (8/$base-font)*1rem;
}
ul {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  li {
    list-style: none;
    button {
      background-color: inherit;
      border: 0;
      color: $color-2;
      font-size: (14/$base-font)*1rem;
      &:hover{
        background-color: inherit;
        color: $color-2;
      }
    }

    .active {
      border: 1px solid $color-2;
      background-color: inherit;
      color: $color-2;
    }
    .block__page-btn {
      padding: .3rem .7rem;
      margin: .5rem .2rem;
    }
  }
  .arrow {
    width: 8px;
    margin-top: 8px;
    cursor: pointer;
  }

  .arrow.previous {
    transform: rotate(180deg);
    margin-right: 1rem;
  }

  .arrow.next {
    margin-left: 1rem;
  }

  .arrow .active {
    display: block;
  }
}
</style>
