import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import report from './modules/report'


// Create store
export const store = createStore({
  modules: {
    auth,
    report
  },
  plugins: [createPersistedState()]
})
