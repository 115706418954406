<template>
  <div class='confirmation-outer' :class="positionClass">
    <div class='confirmation-bkg'></div>
    <div class='confirmation-wrapper'>
      <div class='confirmation' :style="notificationPosition">
        <div class='notification-content'>
          <p class="title">{{ title }}</p>
          <ButtonAutodesk class="btn btn--autodesk-outline" label="Delete" @clicked="$emit('confirm')"/>
          <ButtonAutodesk 
            id="cancel-button" 
            tabindex="0" 
            class="btn btn--autodesk-dark" 
            label="Cancel" 
            @clicked="$emit('cancel')"
            v-on:keydown.esc="$emit('cancel')"
          />
        </div>
      </div>
    </div>
  </div>                
</template>

<script>
import ButtonAutodesk from './buttons/ButtonAutodesk'
  export default {
    name: 'Confirmation',
    components: {
      ButtonAutodesk
    },
    props: {
      positionClass: String,
      title: String
    },
    mounted: function() {
      document.getElementById('cancel-button').focus();
    },
    computed: {
      notificationPosition () {
        let left = this.$store.getters.notificationPosition
        let width = this.$store.getters.notificationWidth
        let style = `left: ${left}px; top: 130px; width: ${width}px;`
        if (this.positionClass && this.positionClass === 'above') {
          style = `left: 0px; top: -140px; width: ${width}px;`
        }
        return style
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.confirmation-outer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  .confirmation-wrapper {
    position: relative;
  }
  &.above {
    .confirmation {
      top: -50px;
    }
    .confirmation-bkg {
      background-color: inherit;
    }
  }
}
.confirmation {
  position: absolute;
  box-shadow: $shadow-5;
  border: 0;
  border-radius: 8px;
  margin-bottom: (24/$base-font)*1rem;
  padding: (18/$base-font)*1rem (44/$base-font)*1rem;
  color: $color-22;
  background: $color-1;
  font-size: 1rem;
  width: 500px;
  .btn {
    margin-right: 1rem;
  }
}

.confirmation-bkg {
  width: 100%;
  height: 100%;
  top: -10px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
}

</style>
