import axios from 'axios'
import { store } from '../store'
import { orderGoals } from './helpers'

let baseUrl = ''
if (process.env.VUE_APP_ENV === 'development') {
  baseUrl = 'http://localhost:8055/'
} else {
  const protocol = window.location.protocol
  const hostName = window.location.hostname
  const apiURL = `${protocol}//${hostName}/api/`
  baseUrl = apiURL
}

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

const oauthUrl = `${baseUrl}auth/login/autodesk?redirect=${window.location.protocol}//${window.location.host}/auth`

const axiosRefresh = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})
// jwt token for puppeteer
async function refreshToken () {
  try {
    const response = await axiosRefresh.post('auth/refresh', {}, { withCredentials: true })
    return response.data.data.access_token
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewGoal () {
  try {
    const response = await axiosInstance.post('items/sustainability_goals')
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewContact () {
  try {
    const response = await axiosInstance.post('items/contacts')
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewFinancialRecord () {
  try {
    const response = await axiosInstance.post('items/financial_records')
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewSupportingSource () {
  try {
    const response = await axiosInstance.post('items/supporting_sources')
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewAccreditation () {
  try {
    const response = await axiosInstance.post('items/accreditations')
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

async function createNewNote (reportId, comment) {
  try {
    const response = await axiosInstance.post('items/overview_notes', {
      o2m: reportId,
      comment: comment
    })
    return response.data.data
  } catch (error) {
    console.log(error.response)
  }
}

// if stage has changes waiting create a log entry
// autosave will not make log entries those changes remain as updated despite being saved and are only logged if save / next is run
// saving/deleting images / new items etc does not clear updateChecks so they remain for next save click
// as first items are initialized on report creation - extra check is needed when a goal is selected/title changes on only child item

function createNewAuditLogEntry (reportId, action, checkUpdateSection, hasUpdates) {
  // apply log entry regardless of update status - i.e images - new items added / deleted
  if (checkUpdateSection === false) {
    createEntry()
  } else {
    if (hasUpdates === true) {
      createEntry()
    }
  }

  function createEntry () {
    try {
      axiosInstance.post('items/audit_log', {
        report: reportId,
        action: action
      })
      fetchReportLogs(reportId)
    } catch (error) {
      console.log(error.response)
    }
  }
}

async function fetchFullReport (id, token = null) {
  try {
    let fields = `*.*,
                  contacts.*,
                  financial_records.*,
                  industry_sector.*,
                  overview_notes.*,
                  region.*,
                  supporting_sources.*,
                  sustainability_goals.sustainability_goal.*,
                  sustainability_goals.user_created.*,
                  sustainability_goals.user_updated.*,
                  sustainability_goals.images.directus_files_id.*,
                  contacts.user_created.*,
                  contacts.user_updated.*,
                  contacts.images.directus_files_id.*,
                  financial_records.user_created.*,
                  financial_records.user_updated.*,
                  financial_records.images.directus_files_id.*,
                  supporting_sources.user_created.*,
                  supporting_sources.user_updated.*,
                  supporting_sources.images.directus_files_id.*,
                  accreditations.user_created.*,
                  accreditations.user_updated.*,
                  accreditations.images.directus_files_id.*`

    if (token === null) {
      fields = `?fields=${fields}`
      const reportResponse = await axiosInstance.get(`/items/reports/${id}${fields}`)
      const reportData = reportResponse.data.data
      if (reportData.sustainability_goals && reportData.sustainability_goals.length > 1) {
        const orderedGoals = orderGoals(reportData.sustainability_goals)
        reportData.sustainability_goals = orderedGoals
      }
      await fetchReportLogs(id)
      return reportData
    } else {
      // we have token - this is a pupeteer call using specific auth
      fields = `?access_token=${token}&fields=${fields}`
      const reportResponse = await axiosRefresh.get(`/items/reports/${id}${fields}`)
      return reportResponse.data.data
    }
  } catch (error) {
    console.log('get report error', error)
  }
}

async function createInitalReportItems (reportObject) {
  try {
    const [newGoal, newContact, newFinancialRecord, newSupportingSource, newAccreditation] = await Promise.all([
      createNewGoal(),
      createNewContact(),
      createNewFinancialRecord(),
      createNewSupportingSource(),
      createNewAccreditation()
    ])
    reportObject.sustainability_goals = [newGoal]
    reportObject.contacts = [newContact]
    reportObject.financial_records = [newFinancialRecord]
    reportObject.supporting_sources = [newSupportingSource]
    reportObject.accreditations = [newAccreditation]
    return reportObject
  } catch (error) {
    return Promise.reject(error)
  }
}

// creates an initial item for each linked collection
async function createReport (reportObject) {
  return new Promise((resolve, reject) => {
    try {
      createInitalReportItems(reportObject).then((updatedReport) => {
        axiosInstance.post('items/reports', updatedReport)
          .then((reportResponse) => {
            const reportID = reportResponse.data.data.id
            fetchFullReport(reportID)
              .then((fullReport) => {
                resolve(fullReport)
              }).catch((err) => {
                reject(err)
              })
          })
      })
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

function deleteItemImages (item) {
  // delete all linked images from item
  if (item.images && item.images.length) {
    item.images.forEach(file => {
      try {
        axiosInstance.delete(`files/${file.directus_files_id.id}`)
      } catch (error) {
        console.log('file deleting error')
      }
    })
  }
}

function removeListItem (item, reportItemList, collectionName) {
  // remove it from current list
  const updatedArray = reportItemList.filter(function (obj) {
    if (obj && obj.id !== null && obj.id !== item.id) {
      return true
    }
  })
  deleteItemApi(item.id, collectionName)
  return updatedArray
}

function deleteItemApi (itemID, collectionName) {
  // remove item from directus
  try {
    axiosInstance.delete(`items/${collectionName}/${itemID}`)
  } catch (error) {
    console.log('item deleting error', error)
  }
}
function fetchOptions () {
  fetchRegions()
  fetchIndustrySectors()
  fetchCompanySizes()
  fetchSustainabilityGoalOptions()
}
async function fetchSustainabilityGoalOptions () {
  try {
    const sustainabilityGoalsResponse = await axiosInstance.get('/items/sustainability_goal_options/')
    let options = sustainabilityGoalsResponse.data.data
    // add combined number and label
    if (options.length) {
      options = options.map((option) => {
        if (option.number && option.label) {
          option.goalLabel = `${option.number}. ${option.label}`
        } else if (option.label) {
          option.goalLabel = option.label
        }
        return option
      })
    }
    store.dispatch('setSustainabilityGoalOptions', options)
  } catch (error) {
    console.log('get fetchSustainabilityGoalOptions error', error)
  }
}
async function fetchIndustrySectors () {
  try {
    const industriesResponse = await axiosInstance.get('/items/industry_sectors/')
    store.dispatch('setIndustrySectors', industriesResponse.data.data)
  } catch (error) {
    console.log('get IndustrySectors error', error)
  }
}
async function fetchCompanySizes () {
  try {
    const companySizesResponse = await axiosInstance.get('/items/company_sizes/')
    store.dispatch('setCompanySizes', companySizesResponse.data.data)
  } catch (error) {
    console.log('get companySizesResponse error', error)
  }
}
async function fetchRegions () {
  try {
    const regionsResponse = await axiosInstance.get('/items/regions/')
    store.dispatch('setRegions', regionsResponse.data.data)
  } catch (error) {
    console.log('get regions error', error)
  }
}
async function fetchReportLogs (reportID) {
  try {
    const response = await axiosInstance.get(
      `items/audit_log?filter[report][_eq]=${reportID}&sort[]=-date_created&fields=action,date_created,report.id,report.company_name,report.user_created.first_name,report.user_created.last_name,user_created.first_name,user_created.last_name&limit=3`
    )
    store.dispatch('setReportLogs', response.data.data)
  } catch (error) {
    console.log('set logs error', error)
  }
}

export { axiosInstance, axiosRefresh, refreshToken, oauthUrl, baseUrl, createNewGoal, createReport, fetchFullReport, deleteItemImages, removeListItem, createNewNote, createNewAuditLogEntry, deleteItemApi, fetchOptions }
