import { createApp } from 'vue'
import App from './App.vue'
// import * as Vue from 'vue' // in Vue 3
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from '@kyvg/vue3-notification'
import router from './router'
import { store } from './store'
import 'bootstrap/dist/css/bootstrap.min.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import setupInterceptors from './services/setupInterceptors'
import { axiosInstance, axiosRefresh, baseUrl } from './services/api'

axios.defaults.baseURL = baseUrl
// const accessToken = getCookie('tdv_access_token')
// axiosInstance.defaults.headers['Authorization'] = 'Bearer ' + accessToken
axiosInstance.defaults.baseURL = baseUrl
axiosRefresh.defaults.baseURL = baseUrl

setupInterceptors()

const app = createApp(App).use(router).use(store)

app.use(VueAxios, axios)
app.use(Notifications)
app.component('v-select', vSelect)
/* app.component('scriptqa', {
  template: '<script type="text/javascript" src="//tags.tiqcdn.com/utag/autodesk/micro-opt-qa/qa/utag.js"></script>'
}) */
app.mount('#app')
