<template>
  <header v-if="simpleHeader" class="header">
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center">
          <img class="logo" src="../assets/images/logo.svg" alt="" />
          <span class="visually-hidden">Autodesk Sustainability Tool</span>
        </div>
      </div>
    </div>
  </header>
  <header v-else class="header">
    <div class="container">
      <transition name="slide">
        <MobileNav v-if="mobileView && showMobNav" @toggleMobNav="showMobNav = !showMobNav"/>
      </transition>
      <transition name="fade">
        <div v-if="mobileView && showMobNav" id='nav-background'></div>
      </transition>
      <div class="row">
        <div class="col d-flex align-items-center">
          <button v-if="mobileView" class="navbar-toggler" type="button" @click="showMobNav = !showMobNav" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
          </button>
          <a href="/" rel="home" class="header__logo">
            <img class="logo" src="../assets/images/logo.svg" alt="" />
            <span class="visually-hidden">Autodesk Sustainability Tool</span>
          </a>
          <span class='me-auto'>
            <SearchForm :hasButton="false" :nav="true" />
          </span>
          <nav v-if="!mobileView" class="report-nav nav nav--primary navbar">
            <div class="container">
              <div class="row">
                <div class="col">
                  <MainNav :mobileView="mobileView"/>
                </div>
              </div>
            </div>
          </nav>
          <UserIcon />
        </div>
      </div>
      <slot></slot>
    </div>
  </header>
</template>

<script>
import SearchForm from '../components/forms/SearchForm'
import MainNav from './MainNav'
import MobileNav from './MobileNav'
import UserIcon from './UserIcon'

export default {
  name: 'Header',
  components: {
    SearchForm,
    MainNav,
    MobileNav,
    UserIcon
  },
  props: {
    simpleHeader: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      inital: '',
      showMobNav: false
    }
  },
  methods: {
    
  },
  created () {
  },
  computed: {
    mobileView: function () {
      return this.$store.getters.mobileView
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.slide-enter-from {
  transform: translateX(-400px);
  left: -400px;
}
.slide-enter-to {
  transform: translateX(0);
  left: 0;
}
.slide-enter-active {
  transition: all .6s ease;
}

.slide-leave-from {
    transform: translateX(0);
    left: 0;
}
.slide-leave-to {
    transform: translateX(-400px);
    left: -400px;
}
.slide-leave-active {
  transition: all .6s ease;
}

/* 
.slide-enter-from {
 */
.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all .3s ease;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all .3s ease;
}
.header {
  background-color: $color-2;
  position: sticky;
  top: 0;
  z-index: 10;
  .logo {
    margin: 1rem 0;
    height: 1.875rem; // 30px
    width: 11.0625rem; // 177px
  }

  .navbar-toggler {
    padding: .5rem .5rem .5rem 0;
    border-right: 1px solid $color-3;
    margin-right: .5rem;
    .navbar-toggler-icon {
      // This would normally come from adding .navbar-dark .bg-dark to .navbar
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.65%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
}

.header__logo {
  display: block;
}

.btn--logout {
  color: $color-1;
  background-color: $color-8;
  border: 0;
  border-radius: 2px;
  &:hover {
    color: $color-2;
    border: 0;
    background-color: $color-5;
  }
}

.nav-link {
  color: $color-1;
  margin: 0;
  text-decoration: none;
}

.report-nav.nav.nav--primary.navbar {
  height: 100%;
  .container, .row, .col, ul {
    height: 100%;
  }
}

#nav-background {
  z-index: 998;
  position: absolute;
  width:150%;
  left: 0;
  height: 100vh;
  background: rgba(0,0,0,.5)
}


</style>
