<template>
  <div class='date-wrapper'>
    <label for="{{ name }}">{{ label }}</label>
    <datepicker 
      class="custom-datepicker"
      v-model="inputVal"
      aria-label="{{ label }}" 
      :inputFormat="inputFormat"
      :clearable="true"
      startingView="week">
    </datepicker>
  </div>
</template>
<script>
import Datepicker from 'vue3-datepicker'
import { format } from 'date-fns'

export default {
  name: 'Text',
  components: {
      Datepicker
  },
  props: {
    label: String,
    modelValue: {
      type: String,
      default: ''
    },
    name: String,
    inputFormat: {
      type: String,
      default: 'do MMM yyyy'
    }
  },
  computed: {
    inputVal: {
      get() {
        // create date object 
        let date = null
        if (this.modelValue && this.modelValue.length) {
          date = new Date(this.modelValue)
        }
        return date
      },
      set(val) {
        if (val && val instanceof Date) {
          let dateFormatted = format(val,'yyyy-MM-dd')
          this.$emit('update:modelValue', dateFormatted)
        } else {
          this.$emit('update:modelValue', null)
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.date-wrapper {
    width: 100%;
    margin-bottom: (24/$base-font)*1rem!important;
  input { 
    width: 100%;
  }
  .v3dp__input_wrapper {
    position: relative;
    .v3dp__clearable{
      position: absolute;
      right: 5px;
      top: 5px;
      left: unset;
      width: 20px;
      height: 20px;
      i {
        display: block;
        font-size: 20px;
        font-style: normal;
      }
    }
  }
  .input-slot-image {
    height: 20px;
    width: auto;
    margin-right: 5px;
  }
  --vdp-hover-bg-color: #000;
  --vdp-selected-bg-color: #000;
}
</style>
