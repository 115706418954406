<template>
  <div class="mobile-navigation">
    <div class='d-flex justify-content-start nav-header'>
        <button class="navbar-toggler" type="button" @click="this.$emit('toggleMobNav')" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <img class="toggle-hide-nav" src="../assets/images/close-nav.svg" alt="toggle hide nav" />
        </button>

        <a href="siteUrl" rel="home" class="header__logo">
          <img class="logo" src="../assets/images/logo-dark.svg" alt="" />
          <span class="visually-hidden">Autodesk Sustainability Tool</span>
        </a>

    </div>
    <nav class="report-nav nav nav--primary navbar">
      <MainNav :mobileView="true"/>
    </nav>
  </div>
</template>

<script>
import MainNav from './MainNav'
export default {
  name: 'Header',
  components: {
    MainNav
  },
  data() {
    return {
     
    }
  },
  methods: {
   
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >

.mobile-navigation {
  width: 400px;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  z-index: 999;
  .nav-header {
    border-bottom: 1px solid $color-16;
    .logo {
      margin: 1rem 0;
      height: 1.875rem; // 30px
      width: 11.0625rem; // 177px
    }
    .navbar-toggler {
      padding: 0 .5rem 0 0;
      margin: .5rem 1rem .5rem .5rem;
      border-right: 1px solid $color-16;
    }
  }
  

  .nav {
    width: 100%;
    background-color: $color-1;
    .navbar-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      .nav-item {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          height: 16px;
          background-image: url('../assets/images/caret-right.svg');
        }
        .nav-link.nav__item__link {
          color: $brand-color-1;
          margin: (10/$base-font)*1rem 0;
          
          padding: 0;
          font-size: 1rem;
        }
      }
    }
  }

}


</style>