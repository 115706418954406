export function setCookie (name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
export function getCookie (name) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function eraseCookie (name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

export function orderGoals (goals) {
  // sort goals by number - dealing with any that may not yet have a goal option set
  let unsetGoals = []
  goals = goals.filter((goal) => {
    if (goal.sustainability_goal === null) {
      unsetGoals = [...unsetGoals, goal]
      return false
    } else {
      return true
    }
  })
  goals.sort(function (x, y) {
    return x.sustainability_goal.number - y.sustainability_goal.number
  })
  goals = [...unsetGoals, ...goals]
  return goals
}

export function setFirstName(email, emailSet, sp, fn){
  emailSet = email;
  sp = emailSet.split('.')
  fn = sp[0]
  return fn
}
export function setLastName(email, emailSet, sp, spOne, ln){
  emailSet = email;
  sp = emailSet.split('.')
  spOne = sp[1].split('@')
  ln = spOne[0]
  return ln
}
