<template>
  <ul class='dropdown-menu show' :class="{ userDrop: user }" v-click-outside='onClickOutside'>
    <li class='userDetails' v-if="user === true">
      <div>
      <p><span v-if="firstName">{{ firstName }}</span> <span v-if="lastName">{{ lastName }}</span></p>
      <p v-if="email">{{ email }}</p>
      <ButtonAutodesk class="btn btn--autodesk-wide btn--autodesk-outline" label="Sign out" @clicked="signOut"/>
    </div>
    </li>
    <li v-for="item in content" class='nav-item nav__item menu-item' :key="`child=${item.slug}`">
      <router-link class="dropdown-item" :class="stage === item.slug? 'active' : ''" :to="`/reports/${reportID}/${item.slug}`">{{ item.title }}</router-link>
    </li>
  </ul>
</template>

<script>
  import vClickOutside from 'click-outside-vue3'
  import { mapActions } from 'vuex'
  import ButtonAutodesk from './buttons/ButtonAutodesk'

  export default {
    name: 'ReportNav',
    props: {
      user: Boolean,
      stage: String,
      content: Array,
      firstName: String,
      lastName: String,
      email: String
    },
    components: {
      ButtonAutodesk
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
    methods: {
      ...mapActions(['logOut']),
      onClickOutside () {
        this.$emit('hide-drop', true)
      },
      async signOut () {
        await this.logOut().then(() => {
          this.$router.push(`/logout`)
        }, error => {
          console.error("log out", error)
        })
      }
    },
    computed: {
      reportID: function () {
        return this.$store.getters.reportID
      }
    }
  }

  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

ul.dropdown-menu {
  background-color: $color-2;
  overflow: visible;
  margin-top: 0;
  background-color: $color-1;
  border-radius: 0px 0px 4px 4px;
  margin-left: 0.5rem;
  padding: 0.5rem 0;
  box-shadow: $shadow-4;
  position: absolute;

  &.userDrop {
    background: $color-1;
    top: 43px;
    right: 0;
    border-radius: 4px 4px 4px 4px;
    li.userDetails {
      padding: .5rem;
    }
    &:before {
      position: absolute;
      right: 8px;
      top: -9px;
      width: 15px;
      height: 11px;
      border: none;
      content: "";
      background-image: url('../assets/images/drop-arrow.svg');
      background-repeat: no-repeat;
    }
  }

  li {
    border-left: 0;
    padding: 0;
    height:100%;
    color: $color-2;
    background: $color-1;
    width: 100%;
    height: 100%;
    .nav-link, .dropdown-item {
      padding: .5rem 3.5rem .5rem 1rem;
      width: 100%;
      text-align: left;
      color: $color-2;
      background: $color-1;
      text-decoration: none;
      position: relative;
      &.active {
          border-bottom: 0;
          background: $color-18;
          
        &:after {
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);
          border: none;
          content: url('../assets/images/tick.svg');
          width:15px;
          height: 17px;

        }
      }
      &:hover {
          background: $color-19;
        }
      &:active {
          background: $color-18;
          border: 0;
      }
    }
  }

}

</style>
