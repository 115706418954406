<template>
  <div id='login-page'>
    <div class='container'>
        <div id="login">
          <h2>Request Password Reset</h2>
          <form action=''>
            <input autocomplete="username" type="email" name="email" v-model="input.email" placeholder="Email" />
            <button type="button" v-on:click="submit()">Request reset</button>
          </form>
          <div id='form-message' v-if="showMessage">
            <h4>{{ message }}</h4>
          </div>
      </div>
    </div>
  </div>
    
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'PasswordResetRequest',
    data() {
      return {
        input: {
          email: "",
          reset_url: "http://localhost:8080/password-reset"
        },
        showMessage: false,
        message: ""
      }
    },
    methods: {
      ...mapActions(['RequestPasswordReset']),
      async submit () {
        if(this.input.email !== "") {
          console.log("here")
          await this.RequestPasswordReset(this.input).then(response => {
            console.log(response)
            this.message = "If the email address is in our system you will recieve an email shortly with a link to reset your password."
            this.showMessage = true
          }, error => {
            console.error("Authentication", error)
            this.message = "Incorrect username or password combination"
            this.showMessage = true
          })
        } else {
          this.showMessage = true
          this.message = "An email must be present"
        }
      }
    },
    mounted () {
      console.log('we were here')
    },
    computed: {
      ...mapGetters(['tealium'])
    }
  }
</script>

<style scoped lang="scss">
  #login-page {
    width: 100%; 
    min-height: 100vh; 
    background: rgba(27, 41, 131, .8);
  }
  
  #login {
    background-color: #fefefe;
    padding: 2em 3em 3em;
    border: 1px solid #888;
    margin: 20% auto;
    width: 80%;
    max-width: 600px;
    border-radius: 0 2.5rem 0 2.5rem;
    position: relative;
    position: relative;
    form {
      display: flex;
      flex-direction: column;
      width: 70%;
      input {
        border-radius: 3px; 
        border: 1px solid $color-8;
        background: $color-10;
        padding: .3rem;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      button {
        background: $color-1;
        color: $color-9;
        width:160px;
        border: 0px;
        padding: .5rem 1rem;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 5px;
        margin-top: .5rem;
      }
    }

    #form-message {
      margin-top: 1rem;
    }
  }

  .logo {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 96px;
  }
</style>