<template>
  <div class='textarea-wrapper'>
    <ToolTip v-if="toolTip" :msg="toolTip" class="top-neg-5"/>
    <label for='{{ name }}'>{{ label }}</label>
    <textarea 
      ref="input"
      class="form-control" 
      id="{{ name }}" 
      name="{{ name }}" 
      :value="modelValue" @keyup="textUpdate"
      aria-label="short description"
      :maxlength="maxCharachterCount > 0 ? maxCharachterCount : ''"
    >
    </textarea>
    <p v-if="charactersRemaining && showCount" class="charachter-count">You have {{ charactersRemaining }} characters remaining.</p>
    <p v-if="charactersRemaining === 0 && showCount" class="charachter-count">You have no characters remaining.</p>
  </div>
</template>
<script>
import ToolTip from '../ToolTip'
export default {
  name: 'TextArea',
  components: {
    ToolTip
  },
  props: {
    maxCharachterCount: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    textInput: String,
    label: String,
    modelValue: {
      type: String,
      default: ''
    },
    showCount: {
      type: Boolean,
      default: false
    },
    name: String,
    toolTip: String
  },
  data() {
    return {
      text: '',
    }
  },
  methods: {
    textUpdate (e) {
      e.target.style.height = e.target.scrollHeight + "px"
      this.$emit('update:modelValue', e.target.value)
    }
  },
  mounted() {
    if (this.height && this.height > 10 && this.height > this.$refs.input.scrollHeight) {
      this.$refs.input.style.height = this.height + "px"
    } else {
      this.$refs.input.style.height = this.$refs.input.scrollHeight + "px"
    }
  },
  computed: {
    charactersRemaining: function () {
      if (this.maxCharachterCount > 0 && this.modelValue) {
        return this.maxCharachterCount - this.modelValue.length
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.textarea-wrapper {
    width: 100%;
    margin-bottom: (24/$base-font)*1rem!important;
    position: relative;
  textarea { 
    width: 100%;
    overflow: hidden;
    height: 44px;
    color: $color-2;
  }
  .charachter-count {
    font-size: (14/$base-font)*1rem;
    left: 0;
    color: $color-22;
    margin: 0;
  }
}
</style>
