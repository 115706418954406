<template>
  <div id="company-wrapper">
    <ButtonAutodesk class="btn--autodesk-step-back" label="Back to Report overview" @clicked="back"/>
    <ReportSuggested v-if="mobileView"  class="mobile"/>
    <h2>The company</h2>
    <h6 class="small">Record your client’s company information here.</h6>
    <form @submit.prevent="save" class='report-overview autodesk-form'>
      <div class="form-group">
          <Text name="customer-serial-number" 
            label="Customer serial number" 
            v-model="customerSerialNumber"
            :maxCharachterCount="99"
            toolTip="Account CSN from Salesforce"
          />
      </div>
      <div class="form-group">
        <Text name="company-website" 
            label="Company website" 
            v-model="companyWebsite"
            :maxCharachterCount="99"
            type="url"
            placeHolder="https://www.example.com"
            errorMessage="Please enter a valid URL"
          />
      </div>
      <div class="form-group">
        <Text name="company-location" 
            label="Company location" 
            v-model="companyLocation"
            :maxCharachterCount="99"
            toolTip="Sales Region from Salesforce, or location of main customer contact"
          />
      </div>
      <div class="row">
        <div class='col'>
          <div class="form-group">
            <Select label="Company size" name="company-size" :isClearable="false" :multiple="false" :firstIsPlaceholder="true" v-model="reportCompanySize" :options="companySizes"/>
          </div>
        </div>
      </div>
      <div class="form-group">
        <Text name="customer-serial-number" 
          label="Company linkedIn profile" 
          v-model="companyLinkedinProfile"
          :maxCharachterCount="99"
          type="url"
          placeHolder="https://www.linkedin.com/in/example"
          errorMessage="Please enter a valid URL"
          />
      </div>
    </form>
  </div>
</template>

<script>
  import Text from '../forms/Text'
  import ButtonAutodesk from '../buttons/ButtonAutodesk'
  import Select from '../forms/Select'
  import ReportSuggested from '../ReportSuggested'

  export default {
    name: 'ReportCompany',
    props: {
      stage: String,
    },
    components: {
      Text,
      ButtonAutodesk,
      Select,
      ReportSuggested
    },
    data() {
      return {
        
      }
    },
    methods: {
      next () {
        this.$router.push('contacts')
      },
      back () {
        this.$router.push('report-overview')
      }
    },
    computed: {
      mobileView: function () {
        return this.$store.getters.mobileView
      },
      companySizes: function () {
        return this.$store.getters.companySizes
      },
      reportCompanySize: {
        get () {
          let size = this.$store.getters.companySizes[0]
          if (this.$store.getters.reportCompanySize && Object.keys(this.$store.getters.reportCompanySize).length !== 0) {
            size = this.$store.getters.reportCompanySize
          }
          return size
        },
        set (value) {
          this.$store.commit('setReportCompanySize', value)
          this.$store.commit('setCompanyHasUpdates', true)
        }
      },
      customerSerialNumber: {
        get () {
          return this.$store.getters.customerSerialNumber
        },
        set (value) {
          this.$store.commit('setCustomerSerialNumber', value)
          this.$store.commit('setCompanyHasUpdates', true)
        }
      },
      companyWebsite: {
        get () {
          return this.$store.getters.companyWebsite
        },
        set (value) {
          this.$store.commit('setCompanyWebsite', value)
          this.$store.commit('setCompanyHasUpdates', true)
        }
      },
      companyLocation: {
        get () {
          return this.$store.getters.companyLocation
        },
        set (value) {
          this.$store.commit('setCompanyLocation', value)
          this.$store.commit('setCompanyHasUpdates', true)
        }
      },
      companyLinkedinProfile: {
        get () {
          return this.$store.getters.companyLinkedinProfile
        },
        set (value) {
          this.$store.commit('setCompanyLinkedinProfile', value)
          this.$store.commit('setCompanyHasUpdates', true)
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

#company-wrapper {
  position: relative;
  border-bottom: 1px solid $color-16;
  padding-bottom: (56/$base-font)*1rem;
  margin-bottom: (72/$base-font)*1rem;
}



</style>
